import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-hot-toast";
import { currentRawDate } from "./firebase.helpers";

const clientPoster = async (dbName, id, data, setter) => {
  try {
    await setDoc(doc(db, dbName, id), data);
    toast.success(`El paciente ${id} ha sido agregado exitosamente`);
    setter(true);
  } catch {
    toast.error(
      "Hubo un problema al agregar un paciente. Porfavor intentelo nuevamente."
    );
    setter(false);
  }
};

const clientUpdater = async (dbName, id, data, setter) => {
  try {
    await updateDoc(doc(db, dbName, id), data);
    toast.success("El paciente ha sido editado exitosamente");
    setter(true);
  } catch {
    toast.error(
      "Hubo un problema al editar el paciente. Porfavor intentelo nuevamente."
    );
    setter(false);
  }
};

const getAllUsers = async (userId) => {
  try {
    const data = [];
    const q = query(collection(db, "users"));
    const querySnapshot = await getDocs(q);

    for (const document of querySnapshot.docs) {
      const userData = document.data();
      const praxisId = userData.praxisId;

      if (praxisId) {
        // Fetch praxis object from the "praxis" collection
        const praxisDoc = await getDoc(doc(db, "praxis", praxisId));
        const praxisData = praxisDoc.data();

        // Combine userData and praxisData
        const combinedData = { ...userData, praxis: praxisData };

        // Push combined data into the array
        data.push(combinedData);
      } else {
        data.push(userData);
      }
    }

    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error; // Re-throw the error if needed
  }
};

const getAllErrors = async (userId) => {
  try {
    const data = [];
    const q = query(collection(db, "errorLogs"));
    const querySnapshot = await getDocs(q);

    for (const document of querySnapshot.docs) {
      const errorData = document.data();
      // const praxisId = userData.praxisId;

      // if (praxisId) {
      //   // Fetch praxis object from the "praxis" collection
      //   const praxisDoc = await getDoc(doc(db, "praxis", praxisId));
      //   const praxisData = praxisDoc.data();

      //   // Combine userData and praxisData
      //   const combinedData = { ...userData, praxis: praxisData };

      //   // Push combined data into the array
      //   data.push(combinedData);
      // } else {
      data.push(errorData);
      // }
    }

    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching error data", error);
    throw error; // Re-throw the error if needed
  }
};

const getClientById = async (clientId) => {
  try {
    let data = null;
    const q = query(collection(db, "users"), where("id", "==", clientId));

    const querySnapshot = await getDocs(q);

    for (const document of querySnapshot.docs) {
      const userData = document.data();
      const praxisId = userData.praxisId;

      if (praxisId) {
        // Fetch praxis object from the "praxis" collection
        const praxisDoc = await getDoc(doc(db, "praxis", praxisId));
        const praxisData = praxisDoc.data();

        // Combine userData and praxisData
        const combinedData = { ...userData, praxis: praxisData };

        // Push combined data into the array
        data = combinedData;
      } else {
        debugger;
        data = userData;
      }
    }
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error; // Re-throw the error if needed
  }
};

// const saveClientLegals = async (userId, contractData) => {
//   const userDocRef = doc(db, "patients", userId);
//   try {
//     const docSnap = await getDoc(userDocRef);
//     if (docSnap.exists()) {
//       const data = docSnap.data();
//       contractData.date = `${currentRawDate.getDate()}-${
//         currentRawDate.getMonth() + 1
//       }-${currentRawDate.getFullYear()}`;
//       data.legals = contractData;
//       await updateDoc(doc(db, "patients", userId), data);
//       toast.success("Contrato guardado exitosamente");
//     } else {
//       console.log("User does not exist");
//     }
//   } catch (err) {
//     toast.error(
//       "Hubo un problema al editar el paciente. Porfavor intentelo nuevamente."
//     );
//     console.log(err);
//   }
// };

// const getPatientByPraxisId = async (praxisId, patientId) => {
//   try {
//     // toast.loading("Buscando Paciente..");
//     const calendarCollectionRef = query(
//       collection(db, "patients"),
//       where("praxisId", "==", praxisId),
//       where("id", "==", patientId)
//     );
//     const querySnapshot = await getDocs(calendarCollectionRef);
//     if (!querySnapshot.empty) {
//       // Process the data
//       const events = querySnapshot.docs.map((doc) => doc.data());
//       // toast.dismiss();
//       return events[0];
//     } else {
//       toast.error("No tiene suficientes permisos");
//     }
//   } catch (error) {
//     console.error("Error fetching remote patient: ", error);
//     throw error;
//   }
// };

const getAllPraxises = async () => {
  try {
    const q = query(collection(db, "praxis"));
    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      // console.log('No documents found in the "praxis" collection.');
      return null;
    }

    const praxises = [];
    snapshot.forEach((doc) => {
      praxises.push(doc.data());
    });

    return praxises;
  } catch (error) {
    console.error("Error fetching praxises:", error);
  }
};

export {
  clientPoster,
  clientUpdater,
  getAllUsers,
  getAllErrors,
  getClientById,
  getAllPraxises,
};
