import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import NationalityDropdown from "../../UI/NationalityDropdown";

const EmailValidation = ({
  setCanContinue,
  setEmail,
  setPassword,
  setName,
  setCI,
  setNationality,
  email,
  password,
  name,
  ci,
  nationality,
}) => {
  const [invalidEmail, setInvalidEmail] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email?.length > 0) {
      if (emailRegex.test(email)) {
        setInvalidEmail(false);
        setEmail(email);
      } else {
        setInvalidEmail(true);
        setEmail(email);
      }
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    if (email && password && name && ci && nationality && !invalidEmail) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [email, password, name, nationality, ci]);

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col md:flex-row">
      <div className="md:w-2/4 mb-6 md:mb-0">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">
          Verificación de correo electrónico
        </h2>
        <p className="text-gray-700 mb-6 leading-relaxed">
          ¡Hola! Para acceder a tus finanzas y gestionar tus citas y datos
          personales, es necesario crear una cuenta. <br />
          <br />
          Una vez validado tu correo, podrás disfrutar de todos los beneficios
          para gestionar tu salud de forma fácil y segura.
        </p>
      </div>
      <form className="flex flex-col w-full md:ml-10">
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Nombre Completo <span className="text-red-500">*</span>
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            CI <span className="text-red-500">*</span>
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="ci"
            onChange={(e) => setCI(e.target.value)}
            value={ci}
          />
        </div>
        <div className="m-2">
          <label>
            Nacionalidad <span className="text-red-500">*</span>
          </label>
          <NationalityDropdown
            className={
              "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            }
            defaultValue={"Paraguay"}
            currentValueSetter={(e) => setNationality(JSON.parse(e))}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="email"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            title="Dirección de correo electrónico inválida"
            onChange={(e) => handleValidation(e.target.value)}
            value={email}
          />
          {invalidEmail && (
            <span className="text-xs text-red-500">El email es inválido</span>
          )}
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Contraseña <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
              required
              type={isPasswordVisible ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              {isPasswordVisible ? (
                <EyeSlashIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailValidation;
