import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import Navbar from "../navbar/Navbar";
import { getPatientSerices } from "../../firebase/firebase.selfpatient";
import { dateFormatter, timeFormatter } from "../../utils/dateFormatter";
import { PatientSelfCategoryButton } from "../UI/buttons/PatientSelfCategoryButton";

const SelfService = ({
  data,
  rawHistory,
  patientId,
  praxisName,
  showMoreStatus,
  view,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [historyToUpdate, setHistoryToUpdate] = useState(null);
  const [historyToDelete, setHistoryToDelete] = useState(null);
  const [showPayments, setShowPayments] = useState(false);

  const [paid, setPaid] = useState([]);
  const [notPaid, setNotPaid] = useState([]);
  const [all, setAll] = useState([]);

  const [toBeConfirmed, setToBeConfirmed] = useState([]);

  const [userView, setUserView] = useState("notPaid");
  const [userViewData, setUserViewData] = useState([]);

  const user = useSelector((state) => state.user?.userDetails);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const getAllRecords = async () => {
      try {
        setIsLoading(true);
        const records = await getPatientSerices(user?.patientRecordId);
        // Temporary arrays for categorizing entries
        let paid = [];
        let notPaid = [];
        let all = [];

        records?.map((entry) => {
          if (entry?.hasBeenPaid) {
            paid.push(entry);
          } else if (!entry?.hasBeenPaid) {
            notPaid.push(entry);
          }
          all.push(entry);
        });

        const sortedPastEntries = (toSort) => {
          let sortedData = toSort.sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
          });
          return sortedData;
        };

        setPaid(sortedPastEntries(paid));
        setNotPaid(sortedPastEntries(notPaid));
        setAll(sortedPastEntries(all));
      } catch (error) {
        setIsLoading(false);
      }
    };
    if (user) getAllRecords();
  }, [user]);

  useEffect(() => {
    switch (userView) {
      case "all":
        return setUserViewData(all);
      case "paid":
        return setUserViewData(paid);
      case "notPaid":
        return setUserViewData(notPaid);
    }
  }, [userView, all]);

  return (
    <Navbar>
      <>
        <main
          className="flex flex-wrap bg-slate-800 h-full"
          style={{ justifyContent: "center" }}
        >
          <div className="w-full mx-auto p-6 bg-white rounded-lg shadow-md">
            <div className="flex flex-col">
              {/* <h2 className="text-2xl font-semibold text-white mb-6">
                Pagos y Servicios
              </h2> */}
              <div className="flex justify-start">
                <PatientSelfCategoryButton
                  userViewState={userView}
                  setViewState={setUserView}
                  buttonId="notPaid"
                  buttonName="Activos"
                />
                <PatientSelfCategoryButton
                  userViewState={userView}
                  setViewState={setUserView}
                  buttonId="paid"
                  buttonName="Pagados"
                />
                <PatientSelfCategoryButton
                  userViewState={userView}
                  setViewState={setUserView}
                  buttonId="all"
                  buttonName="Todas"
                />
              </div>
            </div>

            <ul className="space-y-4 overflow-scroll h-[68vh]">
              {
                userViewData && userViewData.length > 0 ? (
                  userViewData.map((e, i) => {
                    return (
                      <>
                        <li
                          onClick={() =>
                            setShowPayments(showPayments !== null ? null : i)
                          }
                          className="flex sm:flex-row flex-col sm:items-center items-left justify-between bg-slate-700 p-4 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200"
                        >
                          <div className="flex items-start space-x-4">
                            <div className="text-left">
                              <h3 className="text-lg font-medium text-white">
                                {e?.reason}
                              </h3>
                              <div className="flex items-center">
                                <p className="text-sm text-slate-400">
                                  {timeFormatter(new Date(e?.date))}
                                </p>
                                {userView === "all" && (
                                  <>
                                    <div className="text-white mx-1">-</div>
                                    <div className="text-white">
                                      {e?.hasBeenPaid ? (
                                        <div className="text-green-500 bold">
                                          Pagado
                                        </div>
                                      ) : (
                                        <div className="text-red-500 bold">
                                          Pendiente
                                        </div>
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="flex flex-col">
                                <div className="text-white text-2xl font-bold">
                                  {e?.price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </div>
                              </div>

                              <div className="text-white mt-2">
                                {e?.description}
                              </div>
                              {e?.payments?.length > 0 && (
                                <div className="text-white italic mt-2">
                                  {e?.payments?.length > 1
                                    ? " ver pagos"
                                    : " ver pago"}
                                </div>
                              )}
                            </div>
                          </div>
                        </li>
                        {showPayments === i &&
                          e?.payments.map((payment) => {
                            return (
                              <li className="flex sm:flex-row flex-col sm:items-center items-left justify-between bg-green-700 p-2 rounded-lg shadow-sm hover:shadow-lg transition-shadow duration-200">
                                <div className="flex w-full items-start space-x-4 justify-between">
                                  <div className="text-left">
                                    <h3 className="text-xl font-bold text-white">
                                      {payment?.amount
                                        .toString()
                                        .replace(
                                          /\B(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}{" "}
                                      Gs.
                                    </h3>
                                    <div className="flex items-center">
                                      <p className="text-sm text-white">
                                        {dateFormatter(
                                          new Date(
                                            payment?.date?.seconds !== undefined
                                              ? payment?.date?.seconds * 1000
                                              : payment?.date
                                          )
                                        )}
                                      </p>
                                    </div>
                                    <div className="text-white mt-2">
                                      {payment?.description}
                                    </div>
                                  </div>
                                  <div className="flex text-white justify-end w-1/4">
                                    #{payment?.invoice}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </>
                    );
                  })
                ) : (
                  <div
                    className={`m-1 p-1 flex justify-center text-slate-400 rounded-md`}
                  >
                    No tienes Pagos en esta categoria
                  </div>
                )
                // : (
                //   <div
                //     className="flex items-center justify-center"
                //     style={{ height: "70vh", width: "80vw" }}
                //   >
                //     <Spinner />
                //   </div>
                // )
              }
            </ul>
          </div>
        </main>
      </>
    </Navbar>
  );
};

export default SelfService;
