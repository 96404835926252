import { createSlice } from "@reduxjs/toolkit";

export const user = createSlice({
  name: "user",
  initialState: {
    userDetails: null,
    userCredentials: null,
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setUserCredetials: (state, action) => {
      state.userCredentials = action.payload;
    },
    getUserDetails: (state) => {
      return state.userDetails;
    },
    getUserCredetials: (state) => {
      return state.userCredentials;
    },
    removeUserState: (state) => {
      state.userDetails = null;
      state.userCredentials = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDetails,
  setUserCredetials,
  getUserDetails,
  getUserCredetials,
  removeUserState,
} = user.actions;

export default user.reducer;
