import { doc, updateDoc } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { db } from "./firebase";

const updateUserToken = async (token, userId) => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, {
      fcmToken: token, // Overwrite the existing token with the new one
    }).catch(async (error) => {
      if (error.code === "not-found") {
        // User document doesn't exist
      } else {
        throw error;
      }
    });
  } catch (error) {}
};

const sendUserNotification = async (
  fcmToken,
  notificationTitle,
  notificationBody
) => {
  try {
    const functions = getFunctions();
    // const sendNotificationFn = httpsCallable(functions, "sendNotification");
    // debugger;
    // https
    // http://127.0.0.1:5001/odontostarkdev/us-central1/sendNotification

    async function sendNotification(data) {
      const url =
        process.env.REACT_APP_ENV === "DEV"
          ? process.env.REACT_APP_NOTIFICATION_DEV
          : process.env.REACT_APP_NOTIFICATION_PROD;

      try {
        // Make the POST request
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Parse the JSON response
        const responseData = await response.json();

        // Handle the response
        // console.log("Notification sent successfully:", responseData);
        return responseData;
      } catch (error) {
        // Handle errors (network, server, or JSON parse errors)
        console.error("Error sending notification:", error);
        return { success: false, error: error.message };
      }
    }
    let data = {
      fcmToken,
      notificationTitle,
      notificationBody,
      notificationImage:
        "https://firebasestorage.googleapis.com/v0/b/odontostarkdev.appspot.com/o/Color%20variable-19.png?alt=media&token=445304f3-403a-4679-87e8-97cd9b55d0be",
    };
    let result = await sendNotification(data);

    // console.log(result);
    if (result.success) {
      // console.log("Notification sent successfully:", result.response);
    } else {
      console.error("Notification failed:", result.error);
    }
  } catch (error) {
    console.error("Error sending notification:", error);
  }
};

export { updateUserToken, sendUserNotification };
