import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerOwner } from "../../firebase/firebase.user";
import { auth } from "../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../UI/Spinner";
import { useParams } from "react-router-dom";
import usePumble from "../../hooks/pumble/usePumble";
import logo from "../../resources/logo.png";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const Login = () => {
  const [email, setEmail] = useState("");
  const [praxisTel, setPraxisTel] = useState(+595);
  const [praxisName, setPraxisName] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [referal, setReferal] = useState(null);
  const [invalidEmail, setInvalidEmail] = useState(null);
  const [canCreate, setCanCreate] = useState(false);
  const [password, setPassword] = useState(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const userCredentials = useSelector((state) => state.user?.userCredentials);
  const userDetails = useSelector((state) => state.user?.userDetails);
  const dispatch = useDispatch();
  const params = useParams();
  const { loading, error, response, sendRegister } = usePumble();

  useEffect(() => {
    // setIsLoading(true);
    if (params?.praxisReferalId !== undefined) {
      debugger;
      setReferal(params?.praxisReferalId);
      // setClientId(params.id);
      // if (allClients) {
      //   let selectedUser = allClients.find((e) => e.id == params.id);
      //   setClientData(selectedUser);
      //   setIsLoading(false);
      // }

      // if (!allClients && !clientDetails) {
      //   let getUser = async (params) => {
      //     let selectedUser = await getClientById(params);
      //     setClientData(selectedUser);
      //     dispatch(setClientById(selectedUser));
      //     setIsLoading(false);
      //   };
      //   getUser(params.id);
      // }
    }
  }, []);

  const praxisOwner = {
    user: {
      // id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: name,
      lastname: lastName,
      phone: null,
      praxisPhone: praxisTel,
      email: email,
      praxis: null,
      address: null,
      role: "owner",
      praxisId: null,
    },
    praxis: {
      praxisName: praxisName,
      address: null,
      praxisPhone: praxisTel,
      createdAt: new Date().toISOString(),
      currentLicenseType: "premium",
      payments: [
        {
          paid: false,
          membershipStart: new Date().toISOString(),
          amount: 0,
          paymentDate: null,
          licenseType: "free",
          invoice: null,
          additionalInfo: "Self Registered",
        },
      ],
      odontogramServices: [],
      WAMessage: {
        message:
          "Hola%20{nombre},%20estas%20hablando%20con%20el%20{consultorio}.%20Te%20esperamos%20a%20las%20{comienza}%20en%20{direccion}",
      },
      praxisEmail: "",
    },
  };

  useEffect(() => {
    if (user && userDetails) {
      navigate("/dashboard");
    }
  }, [user, userDetails]);

  useEffect(() => {
    if (user && userCredentials === null) {
      let deserializedUser = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(deserializedUser));
    }
  }, [user]);

  const registerUser = async () => {
    setIsLoading(true);
    debugger;
    const registerUser = await registerOwner(praxisOwner, password, referal);
    if (registerUser) {
      dispatch(setUserDetails(registerUser));
      setIsLoading(false);
      sendRegister(`Nuevo usuario Creado: ${registerUser?.email}`);
    }
    setIsLoading(false);
  };

  const handleValidation = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email?.length > 0) {
      if (emailRegex.test(email)) {
        setInvalidEmail(false);
        setEmail(email);
      } else {
        setInvalidEmail(true);
        setEmail(email);
      }
    } else {
      setInvalidEmail(true);
    }
  };

  useEffect(() => {
    if (
      name &&
      lastName &&
      email &&
      !invalidEmail &&
      praxisName &&
      praxisTel?.length > 9
    ) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [name, lastName, email, praxisName, invalidEmail, praxisTel]);

  return (
    <div className="flex min-h-[100vh] items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md space-y-8 mt-14">
        <div className="flex flex-col">
          <img src={logo} />
        </div>
        <div className="mt-4">
          <div className="space-y-4 p-4 bg-white rounded-md shadow-md sm:max-w-md sm:mx-auto">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre <span className="text-red-500">*</span>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={name}
              />
            </div>

            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Apellido <span className="text-red-500">*</span>
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                required
                onChange={(e) => setLastName(e.target.value)}
                className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={lastName}
              />
            </div>

            <div>
              <label
                htmlFor="email-address"
                className="block text-sm font-medium text-gray-700"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                onChange={(e) => handleValidation(e.target.value)}
                className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
              {invalidEmail && (
                <label className="text-xs text-red-500">
                  El email es invalido
                </label>
              )}
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Contraseña <span className="text-red-500">*</span>
              </label>
              <div className="relative">
                <input
                  className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  required
                  type={isPasswordVisible ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <button
                  type="button"
                  onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  className="absolute inset-y-0 right-0 flex items-center pr-3"
                >
                  {isPasswordVisible ? (
                    <EyeSlashIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <EyeIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>

            <div>
              <label
                htmlFor="praxisName"
                className="block text-sm font-medium text-gray-700"
              >
                Nombre del Consultorio/Profesional{" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                id="praxisName"
                name="Nombre del consultorio"
                type="text"
                required
                onChange={(e) => setPraxisName(e.target.value)}
                className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={praxisName}
              />
            </div>

            <div>
              <label
                htmlFor="praxisTel"
                className="block text-sm font-medium text-gray-700"
              >
                Teléfono del Consultorio/Profesional{" "}
                <span className="text-red-500">*</span>
              </label>
              <input
                id="praxisTel"
                name="Telefono del consultorio"
                type="number"
                required
                onChange={(e) => setPraxisTel(e.target.value)}
                className="block w-full mt-1 rounded-md border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={praxisTel}
              />
            </div>
          </div>

          <div className="mt-10">
            <button
              disabled={!canCreate}
              onClick={registerUser}
              className={`group relative flex w-full justify-center rounded-md border border-transparent bg-[#98bbdf] ${
                canCreate && "hover:bg-[#b7cde8] bg-sky-400"
              } py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
            >
              Registrarse
              {isLoading && <Spinner size="small" />}
            </button>
          </div>
          <div className="flex flex-col items-center justify-center mt-3">
            <div className="text-sm">
              <Link
                to="/login"
                className="font-medium text-[#98bbdf] hover:text-[#b7cde8]"
              >
                Iniciar Sesion
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
