import { auth, db, messaging } from "../../firebase/firebase";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserCredetials,
  setUserDetails,
} from "../../store/user/user.reducer";
import { getUserData } from "../../firebase/firebase.user";
import Spinner from "../UI/Spinner";
import toast from "react-hot-toast";
import { setPraxisDetails } from "../../store/praxis/praxis.reducer";
import { getPraxisData } from "../../firebase/firebase.praxis";
import { dateFormatter } from "../../utils/dateFormatter";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import NotificationUI from "../UI/NotificationUI";
import { updateUserToken } from "../../firebase/firebase.notification";

export const PrivateRoute = ({ element, fallbackPath = "/login" }) => {
  const [userToken, setUserToken] = useState(null);
  const [user, loading] = useAuthState(auth);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user?.userDetails);
  const praxis = useSelector((state) => state.praxis?.praxisDetails);

  onMessage(messaging, (payload) => {
    // console.log("Triggered");
    debugger;
    toast.custom(<NotificationUI payload={payload} />, {
      position: "top-right",
    });
  });

  useEffect(() => {
    if (user && userToken == null) {
      let userCredentials = JSON.parse(JSON.stringify(user));
      dispatch(setUserCredetials(userCredentials));

      const getUser = async () => {
        toast.loading("Autenticando");
        let userData = await getUserData(userCredentials.uid);
        let praxisData = await getPraxisData(userData.praxisId);
        // BRING BACK WHEN LICENSE ARE NEEDED AGAIN - FOR BETA NOT RELEVANT
        // if (praxisData?.payments[0]?.membershipStart) {
        //   debugger;
        //   praxisData.payments[0].membershipStart.nanoseconds !== undefined
        //     ? (praxisData.payments[0].membershipStart = new Date(
        //         praxisData.payments[0].membershipStart
        //       ))
        //     : (praxisData.payments[0].membershipStart = dateFormatter(
        //         praxisData.payments[0].membershipStart.toDate()
        //       ));
        // }
        if (praxisData?.WAMessage?.date) {
          praxisData.WAMessage.date.nanoseconds !== undefined
            ? new Date(praxisData.WAMessage.date)
            : (praxisData.WAMessage.date = dateFormatter(
                praxisData.WAMessage.date.toDate()
              ));
        }
        if (userData) {
          dispatch(setUserDetails(userData));
          dispatch(setPraxisDetails(praxisData));
          toast.dismiss();
        }
      };

      if (userDetails === null || praxis === null) {
        getUser();
      }
      setUserToken(user);
    }
  }, [user]);

  const requestPermission = async () => {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        if (token) {
          if (token !== userDetails?.fcmToken) {
            await updateUserToken(token, userDetails?.id);
          }
        } else {
          console.warn("Failed to retrieve FCM token.");
        }
      } catch (error) {
        console.error("Error retrieving FCM token:", error);
      }
    } else if (permission === "denied") {
      //notifications are blocked
      alert("Porfavor habilita notificaciones en tu dispositivo");
    } else if (permission === "default") {
      // console.log("User has not made a decision on notifications.");
    }
  };

  useEffect(() => {
    if (userDetails) {
      requestPermission();
    }
  }, [userDetails]);

  if (loading) {
    return (
      <>
        <div className="bg-gray-800">
          <div className="mx-auto max-w-7xl px-6 sm:px-8 md:px-8 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start sm:w-1/3">
                <div className="flex flex-shrink-0 items-center">
                  <h6 className="mb-0 font-bold text-white capitalize text-xl ml-3">
                    OdontoStark
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex items-center justify-center"
          style={{ height: "80vh" }}
        >
          <Spinner />
        </div>
      </>
    );
  }

  if (user) {
    return element;
  } else {
    return <Navigate to={fallbackPath} />;
  }
};

export default PrivateRoute;
