const newVisitors = () => {
  fetch("https://ipapi.co/json/")
    .then((response) => response.json())
    .then((data) => {
      const newVisitor = async () => {
        await fetch(process.env.REACT_APP_PUMBLE_LOCATION_URL, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "Api-Key": `${process.env.REACT_APP_PUMBLE_KEY}`,
          },
          body: JSON.stringify({
            text: `New Visitor from ${data?.city}`,
          }),
        });
      };
      newVisitor();
    });
};

export default newVisitors;
