import React, { useEffect, useState } from "react";
import ImageUploader from "./ImageUploader";
import Spinner from "../../../../UI/Spinner";
import AllImgs from "./AllImgs";
import { getImgsByUserId } from "../../../../../firebase/firebase.storage";
import Filter from "../../../../filter/Filter";

const ImgDashboard = ({ data, user }) => {
  const [allImgs, setAllImgs] = useState(null);
  const [filteredImgs, setFilteredImgs] = useState(null);
  const [editImg, setEditImg] = useState(false);

  useEffect(() => {
    const getImgs = async () => {
      let allImgs = await getImgsByUserId(data.id);
      setAllImgs(allImgs);
      setFilteredImgs(allImgs);
    };
    if (allImgs === null) {
      getImgs();
    }
  }, [allImgs]);

  return (
    <>
      {data ? (
        <>
          <div
            className="mx-auto w-full flex"
            style={{ backgroundColor: "rgb(44 58 76)" }}
          >
            <ImageUploader
              userId={data.id}
              setAllImgs={setFilteredImgs}
              allImgs={filteredImgs}
              data={data}
              user={user}
              editImg={editImg}
              setEditImg={setEditImg}
            />
            <div className="">
              <Filter
                userData={allImgs}
                filterData={setFilteredImgs}
                originalPatients={allImgs}
                isImgs={true}
              />
            </div>
          </div>
          <div
            className="h-[65vh]"
            style={{ backgroundColor: "rgb(44 58 76)" }}
          >
            {filteredImgs !== undefined && filteredImgs?.length > 0 ? (
              <AllImgs
                allImgs={filteredImgs}
                setEditImg={setEditImg}
                setAllImgs={setFilteredImgs}
              />
            ) : (
              <div
                className="flex items-center justify-center bg-white rounded-t-lg"
                style={{ height: "55vh" }}
              >
                <div>El paciente no posee imagenes disponibles</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="flex items-center justify-center bg-white rounded-t-lg"
          style={{ height: "90.7vh" }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ImgDashboard;
