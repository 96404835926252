import { useState } from "react";

const usePumble = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const sendRegister = async (message) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(process.env.REACT_APP_PUMBLE_REGISTER_URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Api-Key": `${process.env.REACT_APP_PUMBLE_KEY}`,
        },
        body: JSON.stringify({
          text: message,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async (message) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(process.env.REACT_APP_PUMBLE_GENERAL_URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Api-Key": `${process.env.REACT_APP_PUMBLE_KEY}`,
        },
        body: JSON.stringify({
          text: message,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const sendLogin = async (message) => {
    setLoading(true);
    setError(null);
    if (process.env.REACT_APP_ENV === "PROD") {
      let newMessage = message + " - " + process.env.REACT_APP_ENV;
      try {
        const response = await fetch(process.env.REACT_APP_PUMBLE_LOGIN_URL, {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "Api-Key": `${process.env.REACT_APP_PUMBLE_KEY}`,
          },
          body: JSON.stringify({
            text: newMessage,
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setResponse(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const sendAIUsage = async (message, user) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(process.env.REACT_APP_PUMBLE_AI_USAGE_URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          "Api-Key": `${process.env.REACT_APP_PUMBLE_KEY}`,
        },
        body: JSON.stringify({
          text: `
            User: ${user?.name} ${user?.lastname} (${user?.id})
            Praxis: ${user?.praxis} (${user?.praxisId})
            Message: ${message}
            `,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    response,
    sendRegister,
    sendLogin,
    sendAIUsage,
    sendMessage,
  };
};

export default usePumble;
