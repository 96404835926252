import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";

const getPatientSerices = async (patientRecordId) => {
  try {
    // console.log(patientRecordId);
    const patientServicesCollectionRef = query(
      collection(db, "payments"),
      where("patientId", "==", patientRecordId)
    );
    const querySnapshot = await getDocs(patientServicesCollectionRef);

    if (!querySnapshot.empty) {
      // Process the data
      const services = querySnapshot.docs.map((doc) => doc.data());
      return services;
    } else {
      // console.log("No documents found in the 'payments' collection.");
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote payments: ", error);
    throw error;
  }
};

const getPraxisNamesOfPatient = async (patientId) => {
  /** Fetching remote data from Firestore */
  // // debugger;
  try {
    // console.log(patientId);
    const calendarCollectionRef = query(
      collection(db, "calendar"),
      where("patientId", "==", patientId)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);

    if (!querySnapshot.empty) {
      // Process the data
      const events = querySnapshot.docs.map((doc) => doc.data());
      return events;
    } else {
      // console.log("No documents found in the 'calendar' collection.");
      // Handle this case as needed
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

const getCalendarEventsOfPatient = async (patientId) => {
  /** Fetching remote data from Firestore */
  // // debugger;
  try {
    // console.log(patientId);
    const calendarCollectionRef = query(
      collection(db, "calendar"),
      where("patientId", "==", patientId)
    );
    const querySnapshot = await getDocs(calendarCollectionRef);
    if (!querySnapshot.empty) {
      // Process the data
      const events = querySnapshot.docs.map((doc) => doc.data());
      return events;
    } else {
      // console.log("No documents found in the 'calendar' collection.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

const approveOrDenyAppointment = async (patientId, appointmentId, decision) => {
  try {
    // console.log(patientId);
    const calendarCollectionRef = query(
      collection(db, "calendar"),
      where("patientId", "==", patientId),
      where("event_id", "==", appointmentId)
    );

    const querySnapshot = await getDocs(calendarCollectionRef);

    if (!querySnapshot.empty) {
      // Get the first document reference
      const docRef = querySnapshot.docs[0].ref; // Get the document reference
      const event = querySnapshot.docs[0].data(); // Get the event data
      debugger;
      // Update the confirmed field
      await updateDoc(docRef, { confirmed: decision }); // Use the document reference to update
      event.confirmed = decision;
      // console.log("Appointment updated successfully");
      return event;
    } else {
      // console.log("No documents found in the 'calendar' collection.");
      // Handle this case as needed
      return false;
    }
  } catch (error) {
    console.error("Error fetching remote events: ", error);
    throw error;
  }
};

// const getServicesPaymentsAndAppointments = async (patientId) => {
//   /** Fetching remote data from Firestore */
//   // // debugger;
//   try {
//     console.log(patientId);
//     const calendarCollectionRef = query(
//       collection(db, "calendar"),
//       where("patientId", "==", patientId)
//     );
//     const querySnapshot = await getDocs(calendarCollectionRef);

//     if (!querySnapshot.empty) {
//       // Process the data
//       const events = querySnapshot.docs.map((doc) => doc.data());
//       return events;
//     } else {
//       console.log("No documents found in the 'calendar' collection.");
//       // Handle this case as needed
//     }
//   } catch (error) {
//     console.error("Error fetching remote events: ", error);
//     throw error;
//   }
// };

const getServicesPaymentsAndAppointments = async (patientId) => {
  /** Fetching remote data from Firestore */
  try {
    // console.log("Fetching data for patientId:", patientId);
    // debugger;
    // Query for appointments from the 'calendar' collection
    const calendarCollectionRef = query(
      collection(db, "calendar"),
      where("patientId", "==", patientId)
    );
    const calendarSnapshot = await getDocs(calendarCollectionRef);
    const appointments = calendarSnapshot.docs.map((doc) => ({
      ...doc.data(),
      type: "Cita Medica",
      dateField: doc.data().start, // Alias to simplify sorting
    }));

    // Query for services/payments from the 'payments' collection
    const paymentsCollectionRef = query(
      collection(db, "payments"),
      where("patientId", "==", patientId)
    );
    const paymentsSnapshot = await getDocs(paymentsCollectionRef);
    const payments = paymentsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      type: "Servicio",
      dateField: doc.data().date, // Alias to simplify sorting
    }));

    if (!calendarSnapshot.empty || !paymentsSnapshot.empty) {
      // Combine and sort results
      const combinedResults = [...appointments, ...payments];
      // combinedResults.sort(
      //   (a, b) => a.dateField.toDate() - b.dateField.toDate()
      // );
      return combinedResults;
    } else {
      console.log(
        "No documents found in 'calendar' or 'payments' collections."
      );
      return [];
    }
  } catch (error) {
    console.error("Error fetching data from Firestore: ", error);
    throw error;
  }
};

export {
  getPatientSerices,
  getCalendarEventsOfPatient,
  approveOrDenyAppointment,
  getServicesPaymentsAndAppointments,
};
