import React from "react";

const CalendarDateController = ({ calendarRef }) => {
  return (
    <div className="flex">
      <div className="inline-flex" role="group">
        <div
          type="button"
          className={`px-2 flex items-center justify-center font-medium text-gray-900 border border-gray-200 rounded-md hover:bg-gray-100 hover:text-gray-700 focus:z-10`}
          onClick={() => {
            // calendarRef?.current?.scheduler?.handleGotoDay(
            //   new Date().setDate(
            //     new Date(
            //       calendarRef?.current?.scheduler?.selectedDate
            //     ).getDate() - 1
            //   )
            // );

            calendarRef?.current?.scheduler.view == "month" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).setDate(
                    new Date(
                      calendarRef?.current?.scheduler?.selectedDate
                    ).getDate() - 31
                  )
                )
              );

            calendarRef?.current?.scheduler.view == "week" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).setDate(
                    new Date(
                      calendarRef?.current?.scheduler?.selectedDate
                    ).getDate() - 7
                  )
                )
              );
            calendarRef?.current?.scheduler.view == "day" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date().setDate(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).getDate() - 1
                )
              );

            calendarRef?.current?.scheduler.view == "month" &&
              calendarRef?.current?.scheduler?.handleState("month", "view");
            calendarRef?.current?.scheduler.view == "week" &&
              calendarRef?.current?.scheduler?.handleState("week", "view");
            calendarRef?.current?.scheduler.view == "day" &&
              calendarRef?.current?.scheduler?.handleState("day", "view");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </div>
        {
          <>
            {calendarRef?.current?.scheduler.view == "day" && (
              <div
                type="button"
                className={`px-2 py-2 font-bold text-gray-900`}
              >
                {Intl.DateTimeFormat("es-AR", {
                  dateStyle: "short",
                }).format(calendarRef?.current?.scheduler?.selectedDate)}
              </div>
            )}
            {calendarRef?.current?.scheduler.view == "week" && (
              <div
                type="button"
                className={`px-2 py-2 font-bold text-gray-900`}
              >
                {Intl.DateTimeFormat("es-AR", {
                  dateStyle: "short",
                }).format(calendarRef?.current?.scheduler?.selectedDate)}
              </div>
            )}
            {calendarRef?.current?.scheduler.view == "month" && (
              <div
                type="button"
                className={`px-2 py-2 font-bold text-gray-900`}
              >
                {Intl.DateTimeFormat("es-AR", {
                  month: "short",
                })
                  .format(calendarRef?.current?.scheduler?.selectedDate)
                  ?.toUpperCase()}
              </div>
            )}
          </>
        }

        <div
          type="button"
          className={`px-2 flex items-center justify-center font-medium text-gray-900 border border-gray-200 rounded-md hover:bg-gray-100 hover:text-gray-700 focus:z-10`}
          onClick={() => {
            calendarRef?.current?.scheduler.view == "month" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).setDate(
                    new Date(
                      calendarRef?.current?.scheduler?.selectedDate
                    ).getDate() + 31
                  )
                )
              );

            calendarRef?.current?.scheduler.view == "week" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).setDate(
                    new Date(
                      calendarRef?.current?.scheduler?.selectedDate
                    ).getDate() + 7
                  )
                )
              );
            calendarRef?.current?.scheduler.view == "day" &&
              calendarRef?.current?.scheduler?.handleGotoDay(
                new Date().setDate(
                  new Date(
                    calendarRef?.current?.scheduler?.selectedDate
                  ).getDate() + 1
                )
              );

            calendarRef?.current?.scheduler.view == "month" &&
              calendarRef?.current?.scheduler?.handleState("month", "view");
            calendarRef?.current?.scheduler.view == "week" &&
              calendarRef?.current?.scheduler?.handleState("week", "view");
            calendarRef?.current?.scheduler.view == "day" &&
              calendarRef?.current?.scheduler?.handleState("day", "view");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m8.25 4.5 7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
      <div
        type="button"
        className={`px-2 py-2 ml-3 font-semibold text-gray-900`}
        onClick={() => {
          calendarRef?.current?.scheduler?.handleGotoDay(new Date());
        }}
      >
        <p>HOY</p>
      </div>
    </div>
  );
};

export default CalendarDateController;
