import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";

const ProfessionalReferrals = ({ stats, praxis }) => {
  const registrationLink =
    process.env.REACT_APP_ENV === "DEV"
      ? `odontostarkdev.web.app/registrarse/${praxis?.id}`
      : `odontostark.com/registrarse/${praxis?.id}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(registrationLink)
      .then(() => {
        alert("¡Enlace copiado al portapapeles!");
      })
      .catch((err) => {
        console.error("Error al copiar el enlace:", err);
      });
  };

  return (
    <div className="mx-auto m-2">
      <div className="col-span-5 xl:col-span-2">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black">
              Link de Registro para Profesionales
            </h3>
          </div>
          <div className="p-7 overflow-scroll">
            <div className="flex justify-between">
              <div className="mb-4 flex items-center gap-3 w-full">
                <div className="flex justify-between w-full">
                  <span className="mb-1.5 text-black text-sm">
                    {registrationLink}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="p-2 bg-gray-100 flex justify-center items-center align-center"
              onClick={handleCopyToClipboard}
            >
              <ClipboardDocumentListIcon
                className="w-5 h-10"
                aria-hidden="true"
                color="black"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalReferrals;
