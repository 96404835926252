import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import History from "../patientViews/allViews/history/History";
import Odontogram from "../patientOverview/patientInformation/odontogram/Odontogram 2";
import ImgDashboard from "../patientViews/allViews/imgDashboard/ImgDashboard";
import Legals from "../patientViews/allViews/legal/Legals";
import AddPatient from "../addPatient/AddPatient";
import HeaderActions from "../../UI/HeaderActions";
import Navbar from "../../navbar/Navbar";
import Spinner from "../../UI/Spinner";
import { getDataByUserId } from "../../../firebase/firebase.patient";
import Payments from "../patientViews/allViews/payments/Payments";
import FeatureFlag from "../../featureFlag/FeatureFlag";
import { ClientViewContext } from "../../../context/clientView/clientView.context";
import PatientValidation from "../patientOverview/patientStats/PatientValidation";
import noUserImg from "../../../resources/no-user-img.png";
import { calculateAge } from "../../../utils/calculateAge";
import PersonalDetails from "./PersonalDetails";
import { getServicesPaymentsAndAppointments } from "../../../firebase/firebase.selfpatient";
import { returnGeneralDateAndTime } from "../../../utils/dateFormatter";
import {
  IdentificationIcon,
  ExclamationCircleIcon,
  CalendarDaysIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import PatientImgs from "./Imgs/PatientImgs";

const PatientSelfView = () => {
  const [data, setData] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [age, setAge] = useState(0);
  const [paymentsAndAppointments, setPaymentsAndAppointments] = useState(null);
  const [showMore, setShowMore] = useState(true);
  const { state, dispatch } = useContext(ClientViewContext);
  const localPraxis = useSelector((state) => state.user?.userDetails);
  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id !== clientId) {
      setClientId(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    let getNewData = async () => {
      let data = await getDataByUserId(params.id);
      if (data !== undefined) {
        // data.praxisName = localPraxis.praxis;
        // data.appointmentStart = "12/12/2024";
        setData(data);
      } else {
        navigate("/dashboard");
      }
    };
    if (clientId && localPraxis) {
      getNewData();
    }
  }, [clientId, localPraxis]);

  useEffect(() => {
    if (data?.personalDetails?.birthdate) {
      const [day, month, year] = data?.personalDetails?.birthdate.split("-");
      var convertedBd = new Date(`${year}-${month}-${day}`);
      let currentAge = calculateAge(convertedBd);
      setAge(currentAge?.years);
    }
  }, [data?.personalDetails?.birthdate]);

  useEffect(() => {
    if (data?.id && paymentsAndAppointments === null) {
      const fetchUserPaymentsAndCalendar = async () => {
        let newData = await getServicesPaymentsAndAppointments(data?.id);
        setPaymentsAndAppointments(newData);
      };
      fetchUserPaymentsAndCalendar();
    }
  }, [data]);

  return (
    <>
      <Navbar>
        {data ? (
          <>
            {/* <main
              className="flex flex-wrap bg-slate-800 h-full"
              style={{ justifyContent: "center" }}
            >
              <div className="mx-auto w-full">
                <div className="w-full bg-white rounded-t-lg h-full">
                  <div className="flex"></div>
                  <div className="flex">
                    <div className="bg-white rounded-t-lg w-full max-h-[91vh]">
                      {data && (
                        <HeaderActions
                          title={"Agregar Usuario"}
                          userId={data.id}
                          view="patientView"
                        />
                      )}
                      {state && data && state.clientView === "Ficha" && (
                        <div
                        // className={`${!data && "m-5"}`}
                        >
                          <AddPatient
                            headerTitle={`${data.personalDetails.name}`}
                            data={data}
                            setData={setData}
                            editMode={false}
                            view="patientView"
                          />{" "}
                        </div>
                      )}
                      {state && data && state.clientView === "Img" && (
                        <div className="">
                          <FeatureFlag feature={"premium"}>
                            <ImgDashboard
                              data={data}
                              userId={data.id}
                              praxisId={data.praxisId}
                              user={localPraxis}
                              view="patientView"
                            />{" "}
                          </FeatureFlag>
                        </div>
                      )}
                      {state && data && state.clientView === "Odontograma" && (
                        <div className="mt-10">
                          <Odontogram data={data} />
                        </div>
                      )}
                      {state && data && state.clientView === "Historial" && (
                        <div className="m-5">
                          <History
                            rawHistory={data.history}
                            data={data}
                            patientId={clientId}
                            praxisName={localPraxis.praxis}
                            showMoreStatus={showMore}
                            view="patientView"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </main> */}
            <div className="h-full bg-gray-200">
              <div className="bg-white rounded-lg shadow-xl pb-8">
                <div className="w-full h-[100px]">
                  <img
                    src="https://vojislavd.com/ta-template-demo/assets/img/profile-background.jpg"
                    className="w-full h-full"
                  />
                </div>
                <div className="flex flex-col items-center -mt-20">
                  <img
                    src={noUserImg}
                    className="w-40 border-4 border-white rounded-full"
                  />
                  <div className="flex items-center space-x-2 mt-2">
                    <p className="text-2xl">{`${data.personalDetails.name}`}</p>
                    <PatientValidation
                      hasValidated={data?.verified === undefined}
                      stats={data}
                    />
                  </div>
                  <p className="text-gray-700">
                    {data?.personalDetails?.identityNumber}
                  </p>
                  {/* <p className="text-sm text-gray-500">New York, USA</p> */}
                  {data?.personalDetails?.birthdate && (
                    <div className="flex items-center text-sm text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
                        />
                      </svg>
                      {age}
                    </div>
                  )}
                </div>
              </div>

              <div className=" flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4">
                <div className="w-full flex flex-col">
                  <PersonalDetails detail={data?.personalDetails} />

                  {paymentsAndAppointments !== null &&
                    paymentsAndAppointments.length > 0 && (
                      <div className="flex-1 bg-white rounded-lg shadow-xl mt-4 p-8">
                        <h4 className="text-xl text-gray-900 font-bold">
                          Registro de actividad
                        </h4>
                        <div className="relative px-4 overflow-auto h-40vh">
                          {paymentsAndAppointments?.map((item, index) => {
                            if (item?.type === "Cita Medica") {
                              return (
                                <div key={index} className="relative">
                                  {/* Add dashed line if this is not the last item */}
                                  {index <
                                    paymentsAndAppointments.length - 1 && (
                                    <div className="absolute h-[200%] border border-dashed border-opacity-20 border-secondary"></div>
                                  )}
                                  <div className="flex items-center w-full my-8 -ml-1.5">
                                    <div className="w-1/12 z-10">
                                      <div className="w-3.5 h-3.5 bg-blue-600 rounded-full"></div>
                                    </div>
                                    <div className="w-11/12">
                                      <p className="text-md font-semibold flex">
                                        <CalendarDaysIcon
                                          className="h-5 w-5 mr-2"
                                          aria-hidden="true"
                                        />{" "}
                                        {item?.type}
                                      </p>
                                      {item?.praxisName && (
                                        <p className="text-sm flex items-center my-1">
                                          {item?.praxisName}
                                        </p>
                                      )}
                                      {item?.selectedServiceName && (
                                        <p className="text-sm flex items-center my-1">
                                          {item?.selectedServiceName}
                                        </p>
                                      )}
                                      <p className="text-xs text-gray-500">
                                        {returnGeneralDateAndTime(item?.start)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            } else if (item?.type === "Servicio") {
                              return (
                                <div key={index} className="relative">
                                  {/* Add dashed line if this is not the last item */}
                                  {index <
                                    paymentsAndAppointments.length - 1 && (
                                    <div className="absolute h-[200%] border border-dashed border-opacity-20 border-secondary"></div>
                                  )}
                                  <div className="flex items-center w-full my-6 -ml-1.5">
                                    <div className="w-1/12 z-10">
                                      <div className="w-3.5 h-3.5 bg-blue-600 rounded-full"></div>
                                    </div>
                                    <div className="w-11/12">
                                      <p className="text-md font-semibold flex">
                                        <RectangleStackIcon
                                          className="h-5 w-5 mr-2"
                                          aria-hidden="true"
                                        />{" "}
                                        {item?.type}
                                      </p>
                                      <p className="text-sm flex items-center my-1">
                                        {item?.praxisName}
                                      </p>
                                      <p className="text-xs text-gray-500">
                                        {returnGeneralDateAndTime(item?.date)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    )}

                  <div className="flex-1 bg-white rounded-lg shadow-xl mt-4 p-8">
                    <div className="flex items-center justify-between">
                      <h4 className="text-xl text-gray-900 font-bold">
                        Imagenes
                      </h4>
                    </div>
                    <div className="">
                      <PatientImgs
                        data={data}
                        userId={data.id}
                        praxisId={data.praxisId}
                        user={localPraxis}
                        view="patientView"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col w-full 2xl:w-2/3">
                  <div className="flex-1 bg-white rounded-lg shadow-xl p-8">
                    <h4 className="text-xl text-gray-900 font-bold">About</h4>
                    <p className="mt-2 text-gray-700">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Nesciunt voluptates obcaecati numquam error et ut fugiat
                      asperiores. Sunt nulla ad incidunt laboriosam, laudantium
                      est unde natus cum numquam, neque facere. Lorem ipsum
                      dolor sit amet consectetur adipisicing elit. Ut, magni
                      odio magnam commodi sunt ipsum eum! Voluptas eveniet
                      aperiam at maxime, iste id dicta autem odio laudantium
                      eligendi commodi distinctio!
                    </p>
                  </div>
                  <div className="flex-1 bg-white rounded-lg shadow-xl mt-4 p-8">
                    <h4 className="text-xl text-gray-900 font-bold">
                      Statistics
                    </h4>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-4">
                      <div className="px-6 py-6 bg-gray-100 border border-gray-300 rounded-lg shadow-xl">
                        <div className="flex items-center justify-between">
                          <span className="font-bold text-sm text-indigo-600">
                            Total Revenue
                          </span>
                          <span className="text-xs bg-gray-200 hover:bg-gray-500 text-gray-500 hover:text-gray-200 px-2 py-1 rounded-lg transition duration-200 cursor-default">
                            7 days
                          </span>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <div>
                            <svg
                              className="w-12 h-12 p-2.5 bg-indigo-400 bg-opacity-20 rounded-full text-indigo-600 border border-indigo-600"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              ></path>
                            </svg>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex items-end">
                              <span className="text-2xl 2xl:text-3xl font-bold">
                                $8,141
                              </span>
                              <div className="flex items-center ml-2 mb-1">
                                <svg
                                  className="w-5 h-5 text-green-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                  ></path>
                                </svg>
                                <span className="font-bold text-sm text-gray-500 ml-0.5">
                                  3%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-6 py-6 bg-gray-100 border border-gray-300 rounded-lg shadow-xl">
                        <div className="flex items-center justify-between">
                          <span className="font-bold text-sm text-green-600">
                            New Orders
                          </span>
                          <span className="text-xs bg-gray-200 hover:bg-gray-500 text-gray-500 hover:text-gray-200 px-2 py-1 rounded-lg transition duration-200 cursor-default">
                            7 days
                          </span>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <div>
                            <svg
                              className="w-12 h-12 p-2.5 bg-green-400 bg-opacity-20 rounded-full text-green-600 border border-green-600"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                                d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                              ></path>
                            </svg>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex items-end">
                              <span className="text-2xl 2xl:text-3xl font-bold">
                                217
                              </span>
                              <div className="flex items-center ml-2 mb-1">
                                <svg
                                  className="w-5 h-5 text-green-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                  ></path>
                                </svg>
                                <span className="font-bold text-sm text-gray-500 ml-0.5">
                                  5%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-6 py-6 bg-gray-100 border border-gray-300 rounded-lg shadow-xl">
                        <div className="flex items-center justify-between">
                          <span className="font-bold text-sm text-blue-600">
                            New Connections
                          </span>
                          <span className="text-xs bg-gray-200 hover:bg-gray-500 text-gray-500 hover:text-gray-200 px-2 py-1 rounded-lg transition duration-200 cursor-default">
                            7 days
                          </span>
                        </div>
                        <div className="flex items-center justify-between mt-6">
                          <div>
                            <svg
                              className="w-12 h-12 p-2.5 bg-blue-400 bg-opacity-20 rounded-full text-blue-600 border border-blue-600"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                              ></path>
                            </svg>
                          </div>
                          <div className="flex flex-col">
                            <div className="flex items-end">
                              <span className="text-2xl 2xl:text-3xl font-bold">
                                54
                              </span>
                              <div className="flex items-center ml-2 mb-1">
                                <svg
                                  className="w-5 h-5 text-green-500"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                                  ></path>
                                </svg>
                                <span className="font-bold text-sm text-gray-500 ml-0.5">
                                  7%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div className="bg-white rounded-lg shadow-xl p-8">
                <div className="flex items-center justify-between">
                  <h4 className="text-xl text-gray-900 font-bold">
                    Connections (532)
                  </h4>
                  <a href="#" title="View All">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-500 hover:text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                      ></path>
                    </svg>
                  </a>
                </div>
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-8 gap-8 mt-8">
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection1.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Diane Aguilar
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      UI/UX Design at Upwork
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection2.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Frances Mather
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Software Engineer at Facebook
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection3.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Carlos Friedrich
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Front-End Developer at Tailwind CSS
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection4.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Donna Serrano
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      System Engineer at Tesla
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection5.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Randall Tabron
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Software Developer at Upwork
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection6.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      John McCleary
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Software Engineer at Laravel
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection7.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Amanda Noble
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Graphic Designer at Tailwind CSS
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection8.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Christine Drew
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Senior Android Developer at Google
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection9.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Lucas Bell
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Creative Writer at Upwork
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection10.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Debra Herring
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Co-Founder at Alpine.js
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection11.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Benjamin Farrior
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Software Engineer Lead at Microsoft
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection12.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Maria Heal
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Linux System Administrator at Twitter
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection13.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Edward Ice
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Customer Support at Instagram
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection14.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Jeffery Silver
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Software Engineer at Twitter
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection15.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Jennifer Schultz
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Project Manager at Google
                    </p>
                  </a>
                  <a
                    href="#"
                    className="flex flex-col items-center justify-center text-gray-800 hover:text-blue-600"
                    title="View Profile"
                  >
                    <img
                      src="https://vojislavd.com/ta-template-demo/assets/img/connections/connection16.jpg"
                      className="w-16 rounded-full"
                    />
                    <p className="text-center font-bold text-sm mt-1">
                      Joseph Marlatt
                    </p>
                    <p className="text-xs text-gray-500 text-center">
                      Team Lead at Facebook
                    </p>
                  </a>
                </div>
              </div> */}
            </div>
          </>
        ) : (
          <div
            className="flex items-center justify-center bg-white rounded-t-lg"
            style={{ height: "90.7vh" }}
          >
            <Spinner />
          </div>
        )}
      </Navbar>
    </>
  );
};

export default PatientSelfView;
