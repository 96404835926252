import React, { useRef } from "react";
import { QRCodeSVG } from "qrcode.react";

const PraxisQRcode = ({ praxisId }) => {
  const qrRef = useRef(null);
  const permanentUrl =
    process.env.REACT_APP_ENV === "DEV"
      ? `odontostarkdev.web.app/registrarse/${praxisId}/patient`
      : `odontostark.com/registrarse/${praxisId}/patient`;

  return (
    <div className="mx-auto m-2 w-full">
      <div className="col-span-5 xl:col-span-2">
        <div className="rounded-md border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
            <h3 className="font-medium text-black">Registro de Pacientes</h3>
          </div>
          <div className="p-1 overflow-scroll h-[25vh] align-center flex justify-center">
            <div className="flex flex-col justify-center align-center">
              <div ref={qrRef} className="flex justify-center">
                <QRCodeSVG
                  value={permanentUrl}
                  title={"Registro de Pacientes"}
                  size={175}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"L"}
                  id="patientQRCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PraxisQRcode;
