import React, { useEffect, useState } from "react";

const PraxisForm = ({ formStyle, praxis, newDataSetter }) => {
  const [name, setName] = useState(praxis?.praxisName ?? null);
  const [email, setEmail] = useState(praxis?.praxisEmail ?? null);
  const [tel, setTel] = useState(praxis?.praxisPhone ?? null);
  const [address, setAddress] = useState(praxis?.address ?? null);
  const [taxName, setTaxName] = useState(praxis?.taxName ?? null);
  const [ruc, setRUC] = useState(praxis?.ruc ?? null);

  useEffect(() => {
    newDataSetter({
      name: name,
      email: email,
      tel: tel,
      address: address,
      taxName: taxName,
      ruc: ruc,
    });
  }, [name, email, tel, address, taxName, ruc]);

  useEffect(() => {
    setName(praxis?.praxisName ?? null);
    setEmail(praxis?.praxisEmail ?? null);
    setTel(praxis?.praxisPhone ?? null);
    setTaxName(praxis?.taxName ?? null);
    setRUC(praxis?.ruc ?? null);
    setAddress(praxis?.address ?? null);
  }, [praxis]);

  return (
    <div className="px-5 pb-5">
      <div className="flex flex-col lg:flex-row py-3">
        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>Nombre de la Praxis</label>
          <input
            onChange={(e) => setName(e.target.value)}
            defaultValue={praxis?.praxisName}
            className={formStyle}
            // value={name}
            // disabled={!editModeState}
          />
        </div>
        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={praxis?.praxisEmail}
            className={formStyle}
            // value={email}
            // disabled={!editModeState}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row py-3">
        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>Tel de la Praxis</label>
          <input
            onChange={(e) => setTel(e.target.value)}
            defaultValue={praxis?.praxisPhone}
            className={formStyle}
            // value={tel}
            // disabled={!editModeState}
          />
        </div>
        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>Direccion de la Praxis</label>
          <input
            onChange={(e) => setAddress(e.target.value)}
            // defaultValue={praxis?.address}
            defaultValue={praxis?.address}
            className={formStyle}
            value={address}
            // disabled={!editModeState}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row py-3">
        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>Razon Social</label>
          <input
            onChange={(e) => setTaxName(e.target.value)}
            defaultValue={praxis?.taxName}
            className={formStyle}
            // value={taxName}
            // disabled={!editModeState}
          />
        </div>

        <div className="w-full lg:w-2/4 lg:mx-1">
          <label>RUC</label>
          <input
            onChange={(e) => setRUC(e.target.value)}
            defaultValue={praxis?.ruc}
            className={formStyle}
            // value={ruc}
            // disabled={!editModeState}
          />
        </div>
      </div>
    </div>
  );
};

export default PraxisForm;
