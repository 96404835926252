import { useEffect, useState } from "react";
import { postPraxisWAMessage } from "../../firebase/firebase.praxis";

const WhatsAppModal = ({ praxisId, defaultMessage, modalToggler }) => {
  const [message, setMessage] = useState(defaultMessage);

  const publishTemplate = async () => {
    let newMessage;
    if (message) {
      newMessage = message.replace(/ /g, "%20").replace(/\r?\n/g, "%0a");
      await postPraxisWAMessage(praxisId, newMessage, modalToggler);
    }
  };

  useEffect(() => {
    if (defaultMessage) {
      defaultMessage = defaultMessage
        .replaceAll("%20", " ")
        .replaceAll("%0a%0a", "\n");
      setMessage(defaultMessage);
    }
  }, [defaultMessage]);

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <div className="relative my-6 mx-auto lg:w-60vw md:w-60vw">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-12">
            {
              <>
                <div className="flex items-center w-full justify-start p-2 m-2 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-1xl font-semibold">
                    {" "}
                    {`Configuración de Mensajes`}
                  </h3>
                </div>
                <div className="px-5 pb-5">
                  <div className="">
                    <div className="flex flex-col">
                      <div className="mb-5 flex items-center">
                        <div>
                          <div className="text-sm">
                            Palabras magicas
                            <div>{"Nombre del paciente: {nombre}"}</div>
                            <div>{"Inicio de la consulta: {comienza}"}</div>
                            <div>{"Fin de la consulta: {termina}"}</div>
                            <div>{"Nombre del consultorio: {consultorio}"}</div>
                            <div>
                              {"Direccion del consultorio: {direccion}"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mb-5 flex flex-col justify-start">
                        <label
                          for="large-input"
                          className="block mr-2 text-sm font-medium text-gray-900"
                        >
                          Mensaje:{" "}
                        </label>
                        <textarea
                          type="text"
                          id="large-input"
                          className="block h-[20vh] w-full whitespace-pre-wrapresize p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-base focus:ring-blue-500 focus:border-blue-500"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row-reverse"></div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => modalToggler(false)}
                  >
                    Cerrar
                  </button>
                  <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                    <button
                      className=""
                      type="button"
                      onClick={publishTemplate}
                    >
                      Guardar
                    </button>
                    {/* {isLoading && (
                        <div className="ml-2">
                          <Spinner size="small" fill="fill-emerald-800" />
                        </div>
                      )} */}
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default WhatsAppModal;
