import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { toast } from "react-hot-toast";
import { logError } from "./firebase.errortracker";

const addOdontograma = async (userId, odontograma) => {
  const userDocRef = doc(db, "patients", userId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      //   let newId = generateUuid();
      data.odontograma = odontograma;
      await updateDoc(doc(db, "patients", userId), data);
      toast.success("Odontograma agregado exitosamente");
      //   modalSetter(false);
    } else {
      // console.log("Usuario no encontrado");
    }
  } catch (err) {
    toast.error(
      "Hubo un problema al actualizar el Odontograma. Porfavor intentelo nuevamente."
    );
    logError(
      `Issues while trying to update odontogram for patientid: ${userId}. Track: ${err}`
    );
    // console.log(err);
  }
};

const addTreatmentToPraxis = async (praxisId, keyword) => {
  const userDocRef = doc(db, "praxis", praxisId);
  try {
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      let currentPraxis = data;
      // console.log(currentPraxis);
      if (currentPraxis.odontogramServices) {
        if (!currentPraxis.odontogramServices.includes(keyword)) {
          currentPraxis.odontogramServices = [
            ...currentPraxis.odontogramServices,
            keyword,
          ];
        }
      } else {
        currentPraxis.odontogramServices = [keyword];
      }
      await updateDoc(doc(db, "praxis", currentPraxis?.id), currentPraxis);
      return currentPraxis.odontogramServices;
    } else {
      console.error("Not found");
    }
  } catch (error) {
    console.error("Error fetching data", error);
    throw error; // Re-throw the error if needed
  }
};

export { addOdontograma, addTreatmentToPraxis };
