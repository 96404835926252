import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import AddPatientModal from "../patient/addPatient/AddPatientModal";
import countries from "../../utils/countries";

export default function NationalityDropdown({
  currentValueSetter,
  defaultValue,
  className,
  disabled,
}) {
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!disabled && selected) {
      currentValueSetter(JSON.stringify(selected));
    }
  }, [selected]);

  useEffect(() => {
    if (!disabled) {
      let selectedCountry = countries.find((country) =>
        defaultValue?.label
          ? country?.label === defaultValue.label
          : country?.label.split(" ")[1] === defaultValue
      );
      if (selectedCountry === undefined) {
        selectedCountry = {
          value: "PY",
          label: "🇵🇾 Paraguay",
        };
      }
      currentValueSetter(JSON.stringify(selectedCountry));
      setSelected(selectedCountry);
    }
  }, [defaultValue]);

  const filteredPeople =
    query === ""
      ? countries
      : countries.filter((country) =>
          country?.label
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <>
      <Combobox value={selected} onChange={setSelected} disabled={disabled}>
        <div className="relative z-10">
          <div className="flex">
            <div className="relative flex w-full cursor-default overflow-hidden rounded-lg bg-white text-left">
              <Combobox.Input
                className={className}
                displayValue={(person) => person?.label}
                onChange={(event) => setQuery(event.target.value)}
              />
            </div>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  No hay resultados
                </div>
              ) : (
                filteredPeople.map((person, i) => (
                  <Combobox.Option
                    key={i}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={`block truncate m-1 ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person?.label}
                          </span>
                        </div>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {openModal && (
        <AddPatientModal modalState={openModal} modalSetter={setOpenModal} />
      )}
    </>
  );
}
