const dateFormatter = (date) => {
  return date.toISOString().split("T")[0];
};

const monthFormatter = (date) => {
  return date.toISOString().split("T")[0].slice(0, 7);
};

const dateTimeFormatter = (date) => {
  let rawDate = date.toISOString().split("T");
  return rawDate[0] + " - " + rawDate[1];
};

const timeFormatter = (date) => {
  let timeString;
  if (typeof date !== "string") {
    timeString = date.toLocaleTimeString();
  } else {
    timeString = date.split("T")[1];
  }
  const regex = /^(\d{2}:\d{2})/;
  const match = timeString.match(regex);
  if (match) {
    return match[1]; // Output: "07:45"
  } else {
  }
  //   let rawDate = date.toISOString().split("T").split(" ");
  //   return rawDate[1];
};

const deserializeDate = (date) => {
  const dateString = new Date(date?.seconds * 1000);
  return dateString;
};

const returnGeneralDateAndTime = (date) => {
  let dateObj;

  if (typeof date === "string") {
    // Parse ISO string format
    dateObj = new Date(date);
  } else if (date && date.seconds !== undefined) {
    // Parse Firestore Timestamp format
    dateObj = new Date(date.seconds * 1000);
  } else {
    throw new Error("Invalid date format");
  }

  // Format date to dd-mm-yyyy-hh-mm
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  return `${day}-${month}-${year} ${hours}:${minutes}`;
};

const getHourAndMinuteUTC = (date) => {
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export {
  dateFormatter,
  dateTimeFormatter,
  timeFormatter,
  deserializeDate,
  monthFormatter,
  returnGeneralDateAndTime,
  getHourAndMinuteUTC,
};
