import React, { useEffect, useReducer, useRef, useState } from "react";
import useContextMenu from "contextmenu";
import "./NewContextMenu.css";
import "./Tooth.css";

function Tooth({
  number,
  positionX,
  positionY,
  onChange,
  toothData,
  actionHandler,
  toothNumberHandler,
  newService,
  keywords,
}) {
  const initialState = {
    center: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    top: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    bottom: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    left: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
    right: {
      state: 0,
      treatment: "",
      treatmentId: 0,
    },
  };
  const [initialData, setInitialData] = useState(toothData);
  const [toothState, dispatch] = useReducer(reducer, initialData);
  const [toothStateText, setToothStateText] = useState(null);

  useEffect(() => {
    if (toothData) {
      setInitialData(toothData);
    }
  }, [toothData]);

  function reducer(toothState, action) {
    switch (action.type) {
      // case "Caries":
      // case "Restauración":
      // case "Carilla de resina":
      // case "Carilla de porcelana":
      // case "Incrustación":
      // case "Corona":
      // case "Restauración por filtración de endodoncia vieja":
      // case "Restauración por filtración de caries vieja":
      // case "Diente o raíz fracturada":
      // case "Diente con bracket":
      // case "Diente con tubo":
      // case "Diente con botón":
      // case "Endodoncia":
      // case "Provisorio":
      // case "Reemplazo de Amalgama":
      // case "Puente":
      // case "Desgaste":
      // case "Implante":
      // case "Perno":
      // case "Injerto óseo":
      //   let newTooth = toothState[action.zone];
      //   if (newTooth?.state !== undefined) {
      //     newTooth.state = action?.value;
      //     newTooth.treatment = action?.type;
      //     toothState[action.zone] = newTooth;
      //     return { ...toothState };
      //   } else {
      //     newTooth = initialState[action.zone];
      //     newTooth.state = action?.value;
      //     newTooth.treatment = action?.type;
      //     toothState[action.zone] = newTooth;
      //     return { ...initialState };
      //   }
      // case "Diente Ausente":
      //   let absentTeeh = toothState;
      //   if (absentTeeh.center?.state !== undefined) {
      //     absentTeeh.top.state = action?.value;
      //     absentTeeh.top.treatment = action?.type;
      //     absentTeeh.right.state = action?.value;
      //     absentTeeh.right.treatment = action?.type;
      //     absentTeeh.bottom.state = action?.value;
      //     absentTeeh.bottom.treatment = action?.type;
      //     absentTeeh.left.state = action?.value;
      //     absentTeeh.left.treatment = action?.type;
      //     absentTeeh.center.state = action?.value;
      //     absentTeeh.center.treatment = action?.type;
      //     return { ...absentTeeh };
      //   } else {
      //     absentTeeh = initialState;
      //     absentTeeh.top.state = action?.value;
      //     absentTeeh.top.treatment = action?.type;
      //     absentTeeh.right.state = action?.value;
      //     absentTeeh.right.treatment = action?.type;
      //     absentTeeh.bottom.state = action?.value;
      //     absentTeeh.bottom.treatment = action?.type;
      //     absentTeeh.left.state = action?.value;
      //     absentTeeh.left.treatment = action?.type;
      //     absentTeeh.center.state = action?.value;
      //     absentTeeh.center.treatment = action?.type;
      //     return { ...initialState };
      //   }
      case "Nuevo Servicio":
        let partialTooth = toothState[action.zone];
        if (partialTooth?.state !== undefined) {
          partialTooth.state = action?.value;
          partialTooth.treatment = newService.state;
          toothState[action.zone] = partialTooth;
          return { ...toothState };
        } else {
          debugger;
          partialTooth = initialState[action.zone];
          partialTooth.state = action?.value;
          partialTooth.treatment = newService.state;
          toothState[action.zone] = partialTooth;
          return { ...initialState };
        }

      case "clear":
        toothState[action.zone] = initialState.center;
        return { ...toothState };
      default:
        let tooth = toothState[action.zone];
        tooth = initialState[action.zone];
        tooth.state = action?.value;
        tooth.treatment = newService.state;
        toothState[action.zone] = tooth;
        return { ...initialState };
    }
  }

  const clear = (action) => ({ type: "clear", zone: action });

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    onChange(number, toothState);
  }, [toothState, onChange, number]);

  // Done SubMenu
  const doneSubMenu = (place, value) => {
    let allKeywords = ["Nuevo Servicio", ...keywords];
    return allKeywords.reduce((acc, e) => {
      // console.log({ type: e, value: value, zone: place });
      acc[e] = () => {
        dispatch({ type: e, value: value, zone: place });
      };
      return acc;
    }, {});

    // return {
    //   "Nuevo Servicio": () => {
    //     dispatch({ type: "Nuevo Servicio", value: value, zone: place });
    //   },
    // };
    // Caries: () => {
    //   dispatch({ type: "Caries", value: value, zone: place });
    // },
    // Restauración: () => {
    //   dispatch({ type: "Restauración", value: value, zone: place });
    // },
    // "Carilla de resina": () => {
    //   dispatch({ type: "Carilla de resina", value: value, zone: place });
    // },
    // "Carilla de porcelana": () => {
    //   dispatch({ type: "Carilla de porcelana", value: value, zone: place });
    // },
    // Incrustación: () => {
    //   dispatch({ type: "Incrustación", value: value, zone: place });
    // },
    // "Diente Ausente": () => {
    //   dispatch({ type: "Diente Ausente", value: value, zone: place });
    // },
    // Corona: () => {
    //   dispatch({ type: "Corona", value: value, zone: place });
    // },
    // "Restauración por filtración de endodoncia vieja": () => {
    //   dispatch({
    //     type: "Restauración por filtración de endodoncia vieja",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Restauración por filtración de caries vieja": () => {
    //   dispatch({
    //     type: "Restauración por filtración de caries vieja",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Diente o raíz fracturada": () => {
    //   dispatch({
    //     type: "Diente o raíz fracturada",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Diente con bracket": () => {
    //   dispatch({ type: "Diente con bracket", value: value, zone: place });
    // },
    // "Diente con tubo": () => {
    //   dispatch({ type: "Diente con tubo", value: value, zone: place });
    // },
    // "Diente con botón": () => {
    //   dispatch({ type: "Diente con botón", value: value, zone: place });
    // },
    // Endodoncia: () => {
    //   dispatch({ type: "Endodoncia", value: value, zone: place });
    // },
    // Provisorio: () => {
    //   dispatch({ type: "Provisorio", value: value, zone: place });
    // },
    // "Reemplazo de Amalgama": () => {
    //   dispatch({ type: "Reemplazo de Amalgama", value: value, zone: place });
    // },
    // Puente: () => {
    //   dispatch({ type: "Puente", value: value, zone: place });
    // },
    // Desgaste: () => {
    //   dispatch({ type: "Desgaste", value: value, zone: place });
    // },
    // Implante: () => {
    //   dispatch({ type: "Implante", value: value, zone: place });
    // },
    // Perno: () => {
    //   dispatch({ type: "Perno", value: value, zone: place });
    // },
    // "Injerto óseo": () => {
    //   dispatch({ type: "Injerto óseo", value: value, zone: place });
    // },
    // };
  };

  // Todo SubMenu
  const todoSubMenu = (place, value) => {
    let allKeywords = ["Nuevo Servicio", ...keywords];
    return allKeywords.reduce((acc, e) => {
      // console.log({ type: e, value: value, zone: place });
      acc[e] = () => {
        dispatch({ type: e, value: value, zone: place });
      };
      return acc;
    }, {});
    // return {
    //   "Nuevo Servicio": () => {
    //     dispatch({ type: "Nuevo Servicio", value: value, zone: place });
    //   },
    // Caries: () => {
    //   dispatch({ type: "Caries", value: value, zone: place });
    // },
    // Restauración: () => {
    //   dispatch({ type: "Restauración", value: value, zone: place });
    // },
    // "Carilla de resina": () => {
    //   dispatch({ type: "Carilla de resina", value: value, zone: place });
    // },
    // "Carilla de porcelana": () => {
    //   dispatch({ type: "Carilla de porcelana", value: value, zone: place });
    // },
    // Incrustación: () => {
    //   dispatch({ type: "Incrustación", value: value, zone: place });
    // },
    // "Diente Ausente": () => {
    //   dispatch({ type: "Diente Ausente", value: value, zone: place });
    // },
    // Corona: () => {
    //   dispatch({ type: "Corona", value: value, zone: place });
    // },
    // "Restauración por filtración de endodoncia vieja": () => {
    //   dispatch({
    //     type: "Restauración por filtración de endodoncia vieja",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Restauración por filtración de caries vieja": () => {
    //   dispatch({
    //     type: "Restauración por filtración de caries vieja",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Diente o raíz fracturada": () => {
    //   dispatch({
    //     type: "Diente o raíz fracturada",
    //     value: value,
    //     zone: place,
    //   });
    // },
    // "Diente con bracket": () => {
    //   dispatch({ type: "Diente con bracket", value: value, zone: place });
    // },
    // "Diente con tubo": () => {
    //   dispatch({ type: "Diente con tubo", value: value, zone: place });
    // },
    // "Diente con botón": () => {
    //   dispatch({ type: "Diente con botón", value: value, zone: place });
    // },
    // Endodoncia: () => {
    //   dispatch({ type: "Endodoncia", value: value, zone: place });
    // },
    // Provisorio: () => {
    //   dispatch({ type: "Provisorio", value: value, zone: place });
    // },
    // "Reemplazo de Amalgama": () => {
    //   dispatch({ type: "Reemplazo de Amalgama", value: value, zone: place });
    // },
    // Puente: () => {
    //   dispatch({ type: "Puente", value: value, zone: place });
    // },
    // Desgaste: () => {
    //   dispatch({ type: "Desgaste", value: value, zone: place });
    // },
    // Implante: () => {
    //   dispatch({ type: "Implante", value: value, zone: place });
    // },
    // Perno: () => {
    //   dispatch({ type: "Perno", value: value, zone: place });
    // },
    // "Injerto óseo": () => {
    //   dispatch({ type: "Injerto óseo", value: value, zone: place });
    // },
    // };
  };
  // Main ContextMenu
  const menuConfig = (place) => {
    if (newService.setter) {
      newService.setter(null);
      newService.selectedSubActionSetter(null);
    }
    return {
      "Completado 🟢": doneSubMenu(place, 1),
      "Pendiente 🔴": todoSubMenu(place, 2),
      "Limpiar ⚪": () => {
        dispatch(clear(place, 0));
      },
    };
  };

  let getClassNamesByZone = (zone) => {
    if (toothState[zone]?.state === 1) {
      return "done";
    } else if (toothState[zone]?.state === 2) {
      return "to-do";
    }
    return "";
  };

  // Tooth position
  const translate = `translate(${positionX},${positionY})`;

  const onToothClick = (e) => {
    actionHandler(e);
    toothNumberHandler(number);
  };

  return (
    <svg className="tooth">
      <g transform={translate}>
        <polygon
          points="0,0 20,0 15,5 5,5"
          onClick={() => onToothClick(menuConfig("top"))}
          className={getClassNamesByZone("top")}
          onMouseOver={() => setToothStateText(toothState?.top)}
          onMouseLeave={() => setToothStateText(null)}
        ></polygon>
        <polygon
          points="5,15 15,15 20,20 0,20"
          onClick={() => onToothClick(menuConfig("bottom"))}
          className={getClassNamesByZone("bottom")}
          onMouseOver={() => setToothStateText(toothState?.bottom)}
          onMouseLeave={() => setToothStateText(null)}
        />
        <polygon
          points="15,5 20,0 20,20 15,15"
          onClick={() => onToothClick(menuConfig("left"))}
          className={getClassNamesByZone("left")}
          onMouseOver={() => setToothStateText(toothState?.left)}
          onMouseLeave={() => setToothStateText(null)}
        />
        <polygon
          points="0,0 5,5 5,15 0,20"
          onClick={() => onToothClick(menuConfig("right"))}
          className={getClassNamesByZone("right")}
          onMouseOver={() => setToothStateText(toothState?.right)}
          onMouseLeave={() => setToothStateText(null)}
        />
        <polygon
          points="5,5 15,5 15,15 5,15"
          onClick={() => onToothClick(menuConfig("center"))}
          className={getClassNamesByZone("center")}
          onMouseOver={() => setToothStateText(toothState?.center)}
          onMouseLeave={() => setToothStateText(null)}
        />
        {/* {drawToothActions()} */}

        <text
          x="6"
          y="30"
          stroke="navy"
          fill="navy"
          strokeWidth="0.1"
          className="tooth"
        >
          {number}
        </text>
        {toothStateText !== null && (
          <text
            x="0"
            y="40"
            stroke="navy"
            fill="navy"
            strokeWidth="0.2"
            className="tooth"
          >
            {toothStateText?.treatment}
          </text>
        )}
      </g>
    </svg>
  );

  // function drawToothActions() {
  //   // ======// =======// ====== // ===== // ==== Bring back when figures are needed
  //   // let otherFigures = null;
  //   // if (toothState && toothState?.Extract > 0) {
  //   //   otherFigures = (
  //   //     <g stroke={toothState?.Extract === 1 ? "red" : "blue"}>
  //   //       <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />
  //   //       <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
  //   //       <title>Extracción</title>
  //   //     </g>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Fracture > 0) {
  //   //   otherFigures = (
  //   //     <g stroke={toothState?.Fracture === 1 ? "red" : "blue"}>
  //   //       <line x1="0" y1="10" x2="20" y2="10" strokeWidth="2">
  //   //         <title>Fractura</title>
  //   //       </line>
  //   //     </g>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Filter > 0) {
  //   //   otherFigures = (
  //   //     <g stroke={toothState?.Filter === 1 ? "red" : "blue"}>
  //   //       <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2">
  //   //         <title>Fractura</title>
  //   //       </line>
  //   //     </g>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Crown > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Crown === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Corona</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // // New
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // // ======
  //   // if (toothState && toothState?.DienteAusente > 0) {
  //   //   otherFigures = (
  //   //     <g stroke={toothState?.DienteAusente === 1 ? "red" : "blue"}>
  //   //       <line x1="0" y1="0" x2="20" y2="20" strokeWidth="2" />
  //   //       <line x1="0" y1="20" x2="20" y2="0" strokeWidth="2" />
  //   //       <title>Diente Ausente</title>
  //   //     </g>
  //   //   );
  //   // }
  //   // // ======
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // if (toothState && toothState?.Restauraciones > 0) {
  //   //   otherFigures = (
  //   //     <circle
  //   //       cx="10"
  //   //       cy="10"
  //   //       r="10"
  //   //       fill="none"
  //   //       stroke={toothState?.Restauraciones === 1 ? "red" : "blue"}
  //   //       strokeWidth="2"
  //   //     >
  //   //       <title>Restauracion</title>
  //   //     </circle>
  //   //   );
  //   // }
  //   // return otherFigures;
  // }
}

export default Tooth;
