import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { analytics, auth } from "./firebase";
import { toast } from "react-hot-toast";
import {
  doc,
  setDoc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import { logError } from "./firebase.errortracker";
import { logEvent } from "firebase/analytics";

const logInWithEmailAndPassword = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    const userDocRef = doc(db, "users", userCredential.user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      // setTenantIdClaim(userCredential.user, userData.praxisId);
      logEvent(analytics, "Logged in", userData.email);
      return userData;
    } else {
      toast.error("Error - Porfavor verifique sus credenciales");
      logError(`Error while trying to log in`, email);
      return null;
    }
  } catch (err) {
    console.error(err);
    toast.error(`Error - Porfavor verifique sus credenciales`);
    logError(`Issues while trying to log in`, email);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success(
      `Enlace de restablecimiento enviado. Revise su correo (${email}) para más instrucciones.`
    );
  } catch (err) {
    console.error(err);
    toast.error(
      "Error al intentar restablecer la contraseña. Por favor, inténtelo de nuevo más tarde."
    );
    logError(`Issues while trying to reset password`, email);
  }
};

const registerOwner = async (userData, password, referal) => {
  try {
    debugger;
    // Create user in Firebase Authentication
    const authUser = await createUserWithEmailAndPassword(
      auth,
      userData.user.email,
      password
    );

    debugger;
    // Create user data object with additional properties
    const user = {
      id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: userData.user.name,
      lastname: userData.user.lastname,
      phone: userData.user.phone,
      email: userData.user.email,
      praxis: userData.praxis.praxisName,
      address: userData.user.address,
      role: userData.user.role,
      praxisId: null,
    };

    // Add user to the 'users' collection
    await setDoc(doc(db, "users", user.id), user);
    debugger;
    // Create praxis data object
    const praxis = {
      ...userData?.praxis,
      ownerId: user.id, // Use the user's ID as the owner
      id: generateUuid(), // You need to implement a function to generate a unique ID,
      users: [user.id],
      createdAt: new Date().toISOString(),
    };
    debugger;
    // Add praxis to the 'praxis' collection
    await setDoc(doc(db, "praxis", praxis.id), praxis);

    // Add praxis id to the user's praxisId property for filter purposes
    user.praxisId = praxis.id;

    // Update the user document with the praxisId
    await updateDoc(doc(db, "users", user.id), {
      praxisId: praxis.id,
    });
    // setTenantIdClaim(authUser, praxis.id);
    // localStorage.setItem("praxisId", praxis.id);
    // console.log(praxis);
    // console.log(user);
    toast.success("User and praxis created successfully!");
    debugger;
    if (referal) {
      const referalCollectionRef = query(
        collection(db, "praxis"),
        where("id", "==", referal)
      );
      const querySnapshot = await getDocs(referalCollectionRef);
      if (!querySnapshot.empty) {
        // Process the data
        const userData = querySnapshot.docs.map((doc) => doc.data())[0];
        // setTenantIdClaim(userCredential.user, userData.praxisId);
        // logEvent(analytics, "Logged in", userData.email);
        // return userData;
        debugger;
        if (userData.referal) {
          userData.referal = [...userData.referal, user.id];
        } else {
          userData.referal = [user.id];
        }
        debugger;
        await updateDoc(doc(db, "praxis", referal), userData);
      }
    }
    await sendPasswordResetEmail(auth, userData.user.email);
    return user;
  } catch (error) {
    console.error("Error creating user:", error.message);
    throw error;
  }
};

const registerAdmin = async (userData) => {
  try {
    // Create user in Firebase Authentication
    const authUser = await createUserWithEmailAndPassword(
      auth,
      userData.user.email,
      "123456"
    );

    debugger;
    // Create user data object with additional properties
    const user = {
      id: authUser.user.uid, // Use the Firebase Authentication user ID as the document ID
      name: userData.user.name,
      lastname: userData.user.lastname,
      phone: userData.user.phone,
      email: userData.user.email,
      address: userData.user.address,
      role: userData.user.role,
    };

    // Add user to the 'users' collection
    await setDoc(doc(db, "users", user.id), user);
    // console.log(user);
    toast.success("User creado exitosamente!");
    await sendPasswordResetEmail(auth, userData.user.email);
    return user;
  } catch (error) {
    console.error("Error creating user:", error.message);
    throw error;
  }
};

const registerUser = async (userData) => {
  try {
    // Create user in Firebase Authentication
    const authUser = await createUserWithEmailAndPassword(
      auth,
      userData.user.email,
      "123456"
    );
    // Create user data object with additional properties
    const user = {
      id: authUser.user.uid,
      name: userData.user.name,
      lastname: userData.user.lastname,
      phone: userData.user.phone,
      email: userData.user.email,
      address: userData.user.address,
      role: userData.user.role,
      praxis: userData.praxis.praxisName,
      praxisId: userData.praxis.id,
    };
    // Add user to the 'users' collection
    await setDoc(doc(db, "users", user.id), user);
    userData.praxis.users.push(user.id);
    await updateDoc(doc(db, "praxis", userData.praxis.id), userData.praxis);
    toast.success("User created successfully!");
    await sendPasswordResetEmail(auth, userData.user.email);
    // return user;
  } catch (error) {
    console.error("Error creating user:", error.message);
    throw error;
  }
};

const registerAndVerifiedPatient = async (userData) => {
  try {
    debugger;
    // Create user in Firebase Authentication
    const authUser = await createUserWithEmailAndPassword(
      auth,
      userData.personalDetails.email,
      "123456"
    );
    debugger;
    // Create user data object with additional properties
    const user = {
      id: authUser.user.uid,
      name: userData.personalDetails.name,
      phone: userData.personalDetails.tel,
      email: userData.personalDetails.email,
      address: userData.personalDetails.personalAddress,
      role: "patient",
      patientRecordId: userData.id,
      // praxis: userData.praxis.praxisName,
      // praxisId: userData.praxis.id,
      // praxises: [userData.praxis.id],
    };
    // Add user to the 'users' collection
    await setDoc(doc(db, "users", user.id), user);
    // userData.praxis.users.push(user.id);
    // await updateDoc(doc(db, "praxis", userData.praxis.id), userData.praxis);
    // toast.success("User created successfully!");
    await sendPasswordResetEmail(auth, userData.personalDetails.email);
    // return user;
  } catch (error) {
    console.error("Error creating user:", error.message);
    throw error;
  }
};

const logout = async () => {
  try {
    await signOut(auth);
    // console.log("User signed out successfully");
  } catch (error) {
    toast.error(`Error signing out: ${error.message}`, "Sign Out Error");
  }
};

const getUserData = async (userUid) => {
  try {
    const userDocRef = doc(db, "users", userUid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      const userData = userDocSnapshot.data();
      return userData;
    } else {
      // console.log("User document not found");
      return null;
    }
  } catch (err) {
    console.error(err);
    toast.error(`${err.message}`);
  }
};

// const setTenantIdClaim = async (userId, praxisId) => {
//   try {
//     // await auth.auth().setCustomUserClaims(userId, { praxisId: praxisId });
//     await updateProfile(userId, { tenantId: praxisId });
//     console.log(`Successfully set praxisId ${praxisId} for user ${userId.uid}`);
//   } catch (error) {
//     console.error("Error setting custom claim:", error);
//     throw error;
//   }
// };

export {
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
  getUserData,
  registerOwner,
  registerAdmin,
  registerUser,
  registerAndVerifiedPatient,
  // setTenantIdClaim,
};
