import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { generateUuid } from "./firebase.helpers";
import { toast } from "react-hot-toast";
import { deserializeDate } from "../utils/dateFormatter";
import { Timestamp } from "@firebase/firestore";

const getRecordsByUserId = async (userId) => {
  try {
    let data = [];
    const q = query(
      collection(db, "patientRecords"),
      where("patientId", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    for (const document of querySnapshot.docs) {
      const userData = document.data();
      data.push(userData);
    }
    data = data.sort((a, b) => {
      return deserializeDate(b.date) - deserializeDate(a.date);
    });

    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data", error);
    throw error;
  }
};

const updateRecordsById = async (
  userId,
  recordId,
  updatedRecord,
  stateSetter,
  allRecords
) => {
  try {
    const recordCollectionRef = query(
      collection(db, "patientRecords"),
      where("patientId", "==", userId),
      where("id", "==", recordId)
    );
    const querySnapshot = await getDocs(recordCollectionRef);
    if (!querySnapshot.empty) {
      let record = querySnapshot.docs[0].data();
      record = updatedRecord;
      await updateDoc(doc(db, "patientRecords", recordId), record);
      record.date = Timestamp.fromDate(new Date(record.date));

      let updatedRecords = allRecords.map((e) =>
        e.id === recordId ? (e = record) : e
      );
      updatedRecords = updatedRecords.sort((a, b) => {
        return deserializeDate(b.date) - deserializeDate(a.date);
      });

      stateSetter(updatedRecords);
      toast.success("Historial del paciente actualizado exitosamente");
    } else {
      // console.log("Patient Record does not exist");
    }
  } catch (error) {
    console.error("Error fetching remote records: ", error);
    throw error;
  }
};

const createRecord = async (
  newRecord,
  allRecords,
  historyStateSetter,
  modalState
) => {
  try {
    newRecord.id = generateUuid();

    await setDoc(doc(db, "patientRecords", newRecord.id), newRecord);
    newRecord.date = Timestamp.fromDate(new Date(newRecord.date));
    allRecords.push(newRecord);
    allRecords = allRecords.sort((a, b) => {
      return deserializeDate(b.date) - deserializeDate(a.date);
    });

    historyStateSetter(allRecords);
    modalState(false);
    toast.success("History entry added successfully");
  } catch (error) {
    toast.error("Error updating history entry:", error);
  }

  return false;
};

const deleteRecordById = async (historyId, allRecords, historyStateSetter) => {
  try {
    await deleteDoc(doc(db, "patientRecords", historyId));
    allRecords = allRecords.filter((e) => e.id !== historyId);
    historyStateSetter(allRecords);
    toast.success("History entry deleted successfully");

    return true;
  } catch (error) {
    toast.error("Error deleting history entry:", error);
    return false;
  }
};

export {
  getRecordsByUserId,
  createRecord,
  updateRecordsById,
  deleteRecordById,
};
