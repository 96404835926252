import React, { useEffect, useState } from "react";

const CalendarViewController = ({ calendarRef }) => {
  const [currentSelection, setCurrentSelection] = useState("week");
  return (
    <div className="flex w-full">
      <div className="inline-flex rounded-sm shadow-sm" role="group">
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            currentSelection == "day" ? "bg-gray-100" : "bg-white"
          } border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("day", "view");
            setCurrentSelection("day");
          }}
        >
          Día
        </div>
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            currentSelection == "week" ? "bg-gray-100" : "bg-white"
          } border-t border-b border-gray-200 hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("week", "view");
            setCurrentSelection("week");
          }}
        >
          Semana
        </div>
        <div
          type="button"
          className={`px-4 py-2 text-sm font-medium text-gray-900 ${
            currentSelection == "month" ? "bg-gray-100" : "bg-white"
          } border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 focus:z-10 rounded-sm`}
          onClick={() => {
            calendarRef?.current?.scheduler?.handleState("month", "view");
            setCurrentSelection("month");
          }}
        >
          Mes
        </div>
      </div>
    </div>
  );
};

export default CalendarViewController;
