import React, { useEffect, useState } from "react";
import { checkIfExists } from "../../firebase/firebase.patient";
import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { addExistingPatient } from "../../firebase/firebase.praxis";

const Filter = ({ userData, filterData, originalPatients, isImgs }) => {
  const localPraxis = useSelector((state) => state.user?.userDetails);
  const [filter, setFilter] = useState("");
  const [patientExists, setPatientExists] = useState(false);
  const [patientToImport, setPatientToImport] = useState(null);
  const [disableRetry, setDisableRetry] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Regular expression to detect numbers with at least 7 digits
    const isIdentityNumber = /^\d{7,}$/.test(filter);

    if (isIdentityNumber) {
      // Check if `userData` already includes a matching `identityNumber`
      const isAlreadyInUserData = userData.some(
        (e) => e?.personalDetails?.identityNumber === filter
      );
      // If `filter` is not already in `userData`, check if it exists in the database
      if (!isAlreadyInUserData) {
        const checkUsrs = async () => {
          const exists = await checkIfExists(`${filter}-PY`);
          setPatientExists(exists?.length > 0);
          setPatientToImport(true);
        };
        checkUsrs();
      } else {
        setPatientExists(true);
        setPatientToImport(false);
      }
    } else {
      setPatientToImport(false);
    }
  }, [filter, userData]);

  useEffect(() => {
    if (userData) {
      if (filter !== "" && filter !== null) {
        if (isImgs) {
          filterData(
            userData.filter((e) => {
              if (e?.title !== undefined && e?.title.length > 0)
                return e?.title.toLowerCase().includes(filter.toLowerCase());
            })
          );
        } else {
          filterData(
            userData.filter((e) => {
              const name = e?.personalDetails?.name;
              const identityNumber = e?.personalDetails?.identityNumber;

              // Check if either `name` or `identityNumber` matches the filter
              if (name || identityNumber) {
                return (
                  (name && name.toLowerCase().includes(filter.toLowerCase())) ||
                  (identityNumber &&
                    identityNumber.toLowerCase().includes(filter.toLowerCase()))
                );
              }
              return false; // Exclude entries with no matching data
            })
          );
        }
      } else {
        filterData(originalPatients);
      }
    }
  }, [filter]);

  const addPatientToPraxis = async () => {
    if (!disableRetry) {
      setDisableRetry(true);
      let newPatient = await addExistingPatient(
        `${filter}-PY`,
        localPraxis.praxisId
      );
      if (newPatient?.id) {
        setTimeout(() => {
          setDisableRetry(false);
          navigate(`/${newPatient.id}`);
        }, 5000);
      }
    }
  };

  return (
    <div className="flex items-start px-4 sm:px-0 md:px-0 lg:px-0 w-[62vw] sm:w-[75vw] md:w-[75vw] lg:w-[75vw] items-center">
      {patientExists && patientToImport && (
        <InboxArrowDownIcon
          className="w-5 h-10 mr-5"
          aria-hidden="true"
          color="white"
          onClick={addPatientToPraxis}
        />
      )}
      <div className="bg-white w-full rounded-lg p-2 m-2 shadow text-gray placeholder-gray-600  m-1 text-base transition duration-500 ease-in-out transform rounded-lg ring-offset-current ring-offset-2 ring-gray-400 w-40vw">
        <input
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          className="w-full"
          placeholder="Buscar.."
        />
      </div>
    </div>
  );
};

export default Filter;
