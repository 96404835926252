import React, { useEffect, useState } from "react";
import { getImgsByUserId } from "../../../../firebase/firebase.storage";
import AllPatientImgs from "./AllPatientImgs";
import Spinner from "../../../UI/Spinner";

const PatientImgs = ({ data, user }) => {
  const [allImgs, setAllImgs] = useState(null);
  const [filteredImgs, setFilteredImgs] = useState(null);
  const [editImg, setEditImg] = useState(false);

  useEffect(() => {
    const getImgs = async () => {
      let allImgs = await getImgsByUserId(data.id);
      setAllImgs(allImgs);
      setFilteredImgs(allImgs);
    };
    if (allImgs === null) {
      getImgs();
    }
  }, [allImgs]);

  return (
    <>
      {data ? (
        <>
          <div
          // className="h-[65vh]"
          // style={{ backgroundColor: "rgb(44 58 76)" }}
          >
            {filteredImgs !== undefined && filteredImgs?.length > 0 ? (
              <AllPatientImgs
                allImgs={filteredImgs}
                setEditImg={setEditImg}
                setAllImgs={setFilteredImgs}
              />
            ) : (
              <div
                className="flex items-center justify-center bg-white rounded-t-lg"
                style={{ height: "55vh" }}
              >
                <div>No posees imagenes disponibles</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="flex items-center justify-center bg-white rounded-t-lg"
          style={{ height: "90.7vh" }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default PatientImgs;
