import React from "react";
import { Link } from "react-router-dom";
import logo from "../../resources/Color variable-07.png";

const PrivacyPolicy = () => {
  return (
    <div className="isolate bg-gray h-100vh">
      <div className="px-6 pt-6 lg:px-8">
        <div>
          <nav
            className="flex h-9 items-center justify-between"
            aria-label="Global"
          >
            <div className="flex lg:min-w-0 lg:flex-1" aria-label="Global">
              <div className="-m-1.5 p-1.5 flex">
                <img src={logo} className="object-contain md:h-12 h-9 w-auto" />
              </div>
            </div>
            <div className="lg:flex lg:min-w-0 lg:flex-1 lg:justify-end text-gray-400 font-semibold text-sm sm:text-md">
              <div className="px-2">
                <Link to={`/login`}>Iniciar sesión</Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="bg-gray-100 min-h-screen py-10 px-6">
        <div className="max-w-4xl mx-auto bg-white shadow-md rounded-2xl p-8">
          <h1 className="text-3xl font-bold text-center text-[#98bbdf] mb-6">
            Política de Privacidad
          </h1>

          <p className="text-gray-700 mb-4">
            Última actualización:{" "}
            <span className="font-semibold">26 de enero de 2025</span>
          </p>

          <p className="text-gray-700 mb-6">
            En <strong>OdontoStark</strong>, respetamos tu privacidad y estamos
            comprometidos a proteger los datos personales que compartes con
            nosotros. Esta Política de Privacidad describe cómo recopilamos,
            usamos y protegemos tu información.
          </p>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            1. Información que Recopilamos
          </h2>
          <ul className="list-disc pl-6 text-gray-700 mb-6">
            <li>
              Datos personales como nombre, correo electrónico y número de
              teléfono proporcionados al registrarte.
            </li>
            <li>Información médica ingresada en nuestra plataforma.</li>
          </ul>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            2. Uso de la Información
          </h2>
          <p className="text-gray-700 mb-6">
            Utilizamos los datos recopilados para:
          </p>
          <ul className="list-disc pl-6 text-gray-700 mb-6">
            <li>Proveer y mejorar nuestros servicios médicos.</li>
            <li>Garantizar la seguridad de la plataforma.</li>
            <li>
              Cumplir con las regulaciones legales aplicables en Paraguay.
            </li>
          </ul>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            3. Compartición de Información
          </h2>
          <p className="text-gray-700 mb-6">
            No compartimos tu información personal con terceros, excepto en los
            siguientes casos:
          </p>
          <ul className="list-disc pl-6 text-gray-700 mb-6">
            <li>Cuando sea requerido por ley o autoridades gubernamentales.</li>
            <li>
              Para proteger los derechos, propiedad o seguridad de nuestros
              usuarios.
            </li>
          </ul>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            4. Seguridad de los Datos
          </h2>
          <p className="text-gray-700 mb-6">
            Implementamos medidas técnicas y organizativas para proteger tu
            información, incluyendo el uso de Firebase para almacenamiento
            seguro y autenticación. Sin embargo, ningún sistema es completamente
            seguro.
          </p>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            5. Tus Derechos
          </h2>
          <p className="text-gray-700 mb-6">
            Tienes derecho a acceder, modificar o eliminar tu información
            personal en cualquier momento. Para ejercer estos derechos,
            contáctanos a través de nuestro correo electrónico:{" "}
            <strong>odontostarktech@gmail.com</strong>.
          </p>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            6. Cambios a esta Política
          </h2>
          <p className="text-gray-700 mb-6">
            Nos reservamos el derecho de actualizar esta Política de Privacidad
            en cualquier momento. Te notificaremos sobre cambios significativos
            publicando la política actualizada en nuestro sitio web.
          </p>

          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            7. Contacto
          </h2>
          <p className="text-gray-700">
            Si tienes preguntas o inquietudes sobre esta Política de Privacidad,
            puedes contactarnos a través de:
          </p>
          <p className="text-gray-700 font-semibold">
            Correo electrónico: odontostarktech@gmail.com
          </p>
          <p className="text-gray-700 font-semibold">
            Teléfono: +49 176 70568851
          </p>

          <div className="mt-8 text-center">
            <Link
              to={"/"}
              className="bg-gray-400 text-white px-6 py-2 rounded-lg shadow hover:bg-gray-600"
            >
              Volver al Inicio
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
