import React from "react";

export function PatientSelfCategoryButton({
  buttonName,
  userViewState,
  setViewState,
  buttonId,
}) {
  return (
    <p
      class={`text-sm font-semibold mb-6 mx-1 ${
        userViewState === buttonId
          ? "text-slate-950"
          : "text-slate-400  hover:text-slate-950"
      }`}
      onClick={() => setViewState(buttonId)}
    >
      {buttonName}
    </p>
  );
}
