import img from "../../resources/Color variable-19.png";

const NotificationUI = ({ payload }) => {
  return (
    <div className="flex w-full max-w-xs items-center space-x-4 rounded-lg bg-white p-4 shadow-lg ring-1 ring-gray-200 dark:bg-gray-800 dark:ring-gray-700">
      <div className="flex-shrink-0">
        <img
          className="h-10 w-10 rounded-full"
          src={payload?.notification?.image || img}
          alt="Notification Icon"
        />
      </div>
      <div className="flex-1">
        <div className="text-sm font-semibold text-gray-900 dark:text-gray-100">
          {payload?.notification?.title || "Nueva Notificación"}
        </div>
        <div className="mt-1 text-sm text-gray-600 dark:text-gray-400">
          {payload?.notification?.body || "Tienes una nueva notificación"}
        </div>
      </div>
    </div>
  );
};

export default NotificationUI;
