import React, { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router";
import NationalityDropdown from "../../UI/NationalityDropdown";
const BasicInfo = ({
  setCanContinue,
  setBirthdate,
  birthdate,
  setTel,
  tel,
  setAge,
  age,
  setMaritalStatus,
  maritalStatus,
  setGender,
  gender,
  setProfession,
  profession,
  setPersonalAddress,
  personalAddress,
}) => {
  const [error, setError] = useState("");

  // Function to validate date in dd-mm-yyyy format
  const handleDateChange = (e) => {
    const date = e.target.value;
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    // debugger;
    if (datePattern.test(date)) {
      setError(""); // Clear any error if the date is valid
      setBirthdate(date); // Update the age state with the valid date
    } else {
      setError("Por favor, ingrese una fecha en el formato dd-mm-yyyy");
      setBirthdate(date);
    }
  };

  useEffect(() => {
    // debugger;
    if (
      birthdate &&
      maritalStatus &&
      gender &&
      profession &&
      personalAddress &&
      tel &&
      error === ""
    ) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [birthdate, age, maritalStatus, gender, profession, personalAddress, tel]);

  useEffect(() => {
    setCanContinue(false);
  }, []);

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col md:flex-row">
      <div className="md:w-2/4 mb-6 md:mb-0">
        <h2 className="text-3xl font-bold mb-4 text-gray-800">
          Verificación de correo electrónico
        </h2>
        <p className="text-gray-700 mb-6 leading-relaxed">
          ¡Hola! Para acceder a tus finanzas y gestionar tus citas y datos
          personales, es necesario crear una cuenta. <br />
          Usá tu correo electrónico y una contraseña segura. <br />
          Una vez validado tu correo, podrás disfrutar de todos los beneficios
          para gestionar tu salud de forma fácil y segura.
        </p>
      </div>
      <form className="flex flex-col w-full md:ml-10">
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Telefono <span className="text-red-500">*</span>
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="tel"
            onChange={(e) => setTel(e.target.value)}
            value={tel}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Cumpleaños <span className="text-red-500">*</span>
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="text"
            placeholder="dd-mm-yyyy"
            onChange={handleDateChange}
            value={age}
            title="Por favor, ingrese una fecha en el formato dd-mm-yyyy"
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Genero
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="genre"
            onChange={(e) => setGender(e.target.value)}
            value={gender}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Estado Civil
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="civilStatus"
            onChange={(e) => setMaritalStatus(e.target.value)}
            value={maritalStatus}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Profesión
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="profession"
            onChange={(e) => setProfession(e.target.value)}
            value={profession}
          />
        </div>
        <div className="m-2">
          <label className="block text-sm font-medium text-gray-700">
            Dirección Personal
          </label>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
            required
            type="address"
            onChange={(e) => setPersonalAddress(e.target.value)}
            value={personalAddress}
          />
        </div>
        {/* <button
          className={`bg-blue-500 text-white mt-4 p-3 rounded-md hover:bg-blue-600 transition duration-200 ease-in-out disabled:bg-gray-200`}
          onClick={() => setCanContinue(true)}
          disabled={error}
        >
          Siguiente
        </button> */}
      </form>
    </div>
  );
};

export default BasicInfo;
