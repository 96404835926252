import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  setUserCredetials,
  setUserDetails,
} from "../../../store/user/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UI/Spinner";
import { useParams } from "react-router-dom";
import usePumble from "../../../hooks/pumble/usePumble";
import logo from "../../../resources/logo.png";
import odontogramModel from "../../patient/addPatient/odontogramModel";
import WizardUI from "../../UI/Wizard/WizardUI";
import PatientQuestionaire from "../../patient/addPatient/PatientQuestionaire";
import PatientChecks from "../../patient/addPatient/PatientChecks";
import "./patientSelfRegister.css";
import PatientBasicDetails from "./PatientBasicDetails";
import toast from "react-hot-toast";
import useEmail from "../../../hooks/email/useSendEmail";
import { collection } from "firebase/firestore";
import { logInWithEmailAndPassword } from "../../../firebase/firebase.user";
import EmailValidation from "./EmailValidation";
import BasicInfo from "./BasicInfo";
import UserChecks from "./UserChecks";

const PatientSelfRegister = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [name, setName] = useState(null);
  const [ci, setCI] = useState(null);
  const [nationality, setNationality] = useState("Paraguay");

  const [birthdate, setBirthdate] = useState(null);
  const [tel, setTel] = useState(null);
  // const [age, setAge] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState(null);
  const [gender, setGender] = useState(null);
  const [profession, setProfession] = useState(null);
  const [personalAddress, setPersonalAddress] = useState(null);

  const navigate = useNavigate();
  const params = useParams();
  // const { loading, error, response, sendLogin } = usePumble();
  const [canSave, setCanSave] = useState(true);
  const [canContinue, setCanContinue] = useState(false);
  // const [id, setId] = useState(null);
  // const [patientDetails, setPatientDetails] = useState(null);
  const [questionaire, setQuestionaire] = useState(null);
  // const [odontologicPast, setOdontologicPast] = useState(null);
  const dispatch = useDispatch();
  // const [praxisId, setPraxisId] = useState(null);
  // const localPraxis = useSelector((state) => state.user?.userDetails);
  // const [hasSubmited, setHasSubmited] = useState(false);
  // const [validateAccount, setValidateAccount] = useState(false);
  // const [patientId, setPatientId] = useState(false);

  // useEffect(() => {
  //   if (localPraxis && localPraxis.praxisId) {
  //     setPraxisId(localPraxis?.praxisId);
  //   }
  // }, [localPraxis]);

  // useEffect(() => {
  //   if (savedDetails) {
  //     setPatientDetails(savedDetails);
  //   }
  // }, [savedDetails]);
  useEffect(() => {
    // console.log(nationality);
  }, [nationality]);

  let patient = {
    // id: id,
    praxisId: [params?.praxisId],
    odontoId: `${ci}-${nationality?.value}`,
    personalDetails: {
      name: name,
      tutorName: "-",
      tel: tel,
      nationality: nationality,
      birthdate: birthdate,
      maritalStatus: maritalStatus,
      gender: gender,
      profession: profession,
      personalAddress: personalAddress,
      email: email,
      identityNumber: ci,
    },
    medicHistory: {
      isCurrentlyUnderMedicalTreatment:
        questionaire?.isCurrentlyUnderMedicalTreatment,
      isUsingMedicine: questionaire?.isUsingMedicine,
      needsBloodTransf: questionaire?.needsBloodTransf,
      hadSurgery: questionaire?.hadSurgery,
      alergyToMedicine: questionaire?.alergyToMedicine,
      smokes: questionaire?.smokes,
      drinks: questionaire?.drinks,
      pregnant: questionaire?.pregnant,
      canUseAnestesia: questionaire?.canUseAnestesia,
    },
    odontologicPast: {
      lastMedicalCheck: new Date(),
      reasonForLosingTheet: "",
      reasonForLosingTheetText: "",
      mouthHygiene: "",
      lastVisit: `${new Date()}`,
    },
    odontograma: odontogramModel,
  };

  const submitUser = async (e) => {
    e.preventDefault();
    // console.log(patient);
    if (password || name || ci || nationality || (email && params.praxisId)) {
      debugger;
      try {
        //   // console.log(praxis);
        debugger;
        const response = await fetch(
          // "http://127.0.0.1:5001/odontostarkdev/us-central1/createUserAccountAndPatientRecord",
          process.env.REACT_APP_ENV === "DEV"
            ? "https://us-central1-odontostarkdev.cloudfunctions.net/createUserAccountAndPatientRecord"
            : "https://us-central1-odontostark-66ab6.cloudfunctions.net/createUserAccountAndPatientRecord",
          // "https://createuseraccountandpatientrecord-kdozmncc2a-uc.a.run.app",
          // "https://us-central1-odontostark-66ab6.cloudfunctions.net/sendBotMessage",
          // "http://127.0.0.1:4000/functions/sendBotMessage",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              patient: patient,
              email: email,
              password: password,
              // praxisId: params.praxisId,
              //   nationality: nationality,
            }),
          }
        );
        debugger;
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        debugger;
        const data = await response.json();
        // console.log(data);
        const loginUserData = await logInWithEmailAndPassword(email, password);
        if (loginUserData) {
          dispatch(setUserDetails(loginUserData));
          // setIsLoading(false);
          navigate(`/self/${data?.userId}`);
          // sendLogin(`Inicio de Sesion de ${loginUserData?.email}`);
        }
        navigate("/login");
        // navigate(`/self/${data?.userId}`);
        // setResponse(data);
      } catch (error) {
        debugger;
        //   setError(error.message);
      } finally {
        //   setLoading(false);
      }
    }
  };

  useEffect(() => {
    // console.log(canContinue);
  }, [canContinue]);

  return (
    <>
      <div
        className="justify-center items-center flex flex-col fixed inset-0 outline-none focus:outline-none"
        style={{ zIndex: "10000" }}
      >
        <img src={logo} className="h-[7vh]" alt="Logo" />
        {/* {!validateAccount ? ( */}
        <div className="relative my-6 mx-auto lg:w-80vw md:w-70vw sm:w-80vw w-80vw w-full">
          <>
            {/* Wizard Content - Fill remaining height */}
            <div className="px-5 w-full h-[80vh] flex-grow">
              <WizardUI
                canSave={canSave}
                saveSetter={setCanSave}
                canContinue={canContinue}
              >
                {/* Step 0 - Validate Email */}
                <div>
                  <EmailValidation
                    setCanContinue={setCanContinue}
                    setEmail={setEmail}
                    setName={setName}
                    setPassword={setPassword}
                    setCI={setCI}
                    setNationality={setNationality}
                    email={email}
                    name={name}
                    password={password}
                    ci={ci}
                    nationality={nationality}
                  />
                </div>
                {/* Step 1 - Patient Details */}
                <div>
                  <BasicInfo
                    setCanContinue={setCanContinue}
                    setTel={setTel}
                    setMaritalStatus={setMaritalStatus}
                    setGender={setGender}
                    setProfession={setProfession}
                    setPersonalAddress={setPersonalAddress}
                    setBirthdate={setBirthdate}
                    birthdate={birthdate}
                    tel={tel}
                    maritalStatus={maritalStatus}
                    profession={profession}
                    personalAddress={personalAddress}
                    gender={gender}
                  />
                </div>

                {/* Step 3 - User Checks */}
                <div>
                  <UserChecks
                    questionaire={questionaire}
                    questionaireSetter={setQuestionaire}
                    setCanContinue={setCanContinue}
                  />
                </div>
              </WizardUI>
            </div>
            {/* Footer */}
            <div className="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b">
              {canSave && (
                // <div className="flex bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                //   {/* Save Button */}
                //   <button
                //     className=""
                //     type="button"
                //     onClick={saveClientHandler}
                //   >
                //     {" "}
                //     Guardar{" "}
                //   </button>
                //   {isLoading && (
                //     <div className="ml-2">
                //       <Spinner size="small" fill="fill-emerald-800" />
                //     </div>
                //   )}
                // </div>

                <button
                  className="bg-blue-500 text-white mt-4 p-3 rounded-md hover:bg-blue-600 transition duration-200 ease-in-out"
                  //   type="submit"
                  onClick={(e) => submitUser(e)}
                >
                  Crear Cuenta
                </button>
              )}
            </div>
          </>
        </div>
        {/* ) : (
          <div className="relative my-6 mx-auto lg:w-80vw md:w-70vw sm:w-80vw w-80vw w-full bg-white shadow-lg rounded-lg p-6 border border-gray-300">
            <h2 className="text-2xl font-extrabold text-center text-gray-800 mb-4">
              ¡Bienvenido a tu espacio de salud!
            </h2>
            <p className="text-center text-gray-700 mb-4">
              <strong>Accedé a tus detalles médicos, citas y servicios.</strong>
            </p>
            <div className="flex justify-center">
              <button
       
                className="px-6 py-3 text-white bg-slate-700 hover:bg-slate-800 rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 shadow-lg"
              >
                Verificar Cuenta
              </button>
            </div>
    
            <p className="text-center text-gray-600 mt-4">
              Porfavor verifica tus datos para seguir.
            </p>
          </div>
        )} */}
      </div>
    </>
  );
};

export default PatientSelfRegister;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { auth } from "../../../firebase/firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { setUserCredetials } from "../../../store/user/user.reducer";
// import { useDispatch, useSelector } from "react-redux";
// import Spinner from "../../UI/Spinner";
// import usePumble from "../../../hooks/pumble/usePumble";
// import logo from "../../../resources/logo.png";
// import odontogramModel from "../../patient/addPatient/odontogramModel";
// import WizardUI from "../../UI/Wizard/WizardUI";
// import PatientQuestionaire from "../../patient/addPatient/PatientQuestionaire";
// import PatientChecks from "../../patient/addPatient/PatientChecks";
// import "./patientSelfRegister.css";
// import PatientBasicDetails from "./PatientBasicDetails";
// import toast from "react-hot-toast";
// import useEmail from "../../../hooks/email/useSendEmail";
// import { collection } from "firebase/firestore";
// import { logInWithEmailAndPassword } from "../../../firebase/firebase.user";

// const PatientSelfRegister = () => {
//   const [isLoading, setIsLoading] = useState(false);
//   const [referal, setReferal] = useState(null);
//   const [user] = useAuthState(auth);
//   const navigate = useNavigate();
//   const userCredentials = useSelector((state) => state.user?.userCredentials);
//   const userDetails = useSelector((state) => state.user?.userDetails);
//   const dispatch = useDispatch();
//   const params = useParams();
//   const { sendLogin } = usePumble();
//   const [canSave, setCanSave] = useState(true);
//   const [canContinue, setCanContinue] = useState(false);
//   const [patientDetails, setPatientDetails] = useState(null);
//   const [questionaire, setQuestionaire] = useState(null);
//   const [odontologicPast, setOdontologicPast] = useState(null);
//   const [praxisId, setPraxisId] = useState(null);
//   const [hasSubmitted, setHasSubmitted] = useState(false);
//   const [validateAccount, setValidateAccount] = useState(false);
//   const { authenticateAndCreateNewUser } = useEmail();
//   const savedDetails = localStorage.getItem("patientDetails");

//   useEffect(() => {
//     const localPraxis = userDetails?.praxisId;
//     if (localPraxis) setPraxisId(localPraxis);
//     if (savedDetails) setPatientDetails(JSON.parse(savedDetails));
//     if (params?.id) setReferal(params.id);
//     if (params?.praxisId) setPraxisId(params.praxisId);
//     if (params?.praxisId && params?.patientId !== undefined) {
//       debugger;
//       setValidateAccount(true);
//     }
//   }, [params, userDetails, savedDetails]);

//   useEffect(() => {
//     if (user && userDetails) navigate("/dashboard");
//     if (user && userCredentials === null) {
//       dispatch(setUserCredetials(JSON.parse(JSON.stringify(user))));
//     }
//   }, [user, userDetails, userCredentials, navigate, dispatch]);

//   const saveClientHandler = async () => {
//     if (!praxisId) {
//       toast.error("Por favor, vuelva a iniciar sesión", "Sign Out Error");
//       return;
//     }

//     const newId = Math.random().toString(16).slice(2);
//     const userEmail =
//       typeof patientDetails === "string"
//         ? JSON.parse(patientDetails)?.email
//         : patientDetails?.email;

//     await authenticateAndCreateNewUser(userEmail, newId, praxisId);
//     setValidateAccount(true);
//   };

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(async (user) => {
//       if (user) {
//         const patientRef = collection("patients").doc(user.uid);
//         const patientDoc = await patientRef.get();

//         if (!patientDoc.exists) {
//           await patientRef.set({
//             praxisId,
//             email: user.email,
//             createdAt: new Date(),
//           });
//         }
//       } else if (params?.patientId && !user) {
//         debugger;
//         const logIn = async () => {
//           const details =
//             typeof savedDetails === "string"
//               ? JSON.parse(savedDetails)
//               : savedDetails;
//           debugger;
//           const loginUserData = await logInWithEmailAndPassword(
//             details?.email,
//             "123456"
//           );
//           if (loginUserData) {
//             sendLogin(
//               `Inicio de Sesion de nuevo paciente ${loginUserData?.email}`
//             );
//           }
//         };
//         logIn();
//       }
//     });

//     return () => unsubscribe();
//   }, [praxisId, user, savedDetails, sendLogin, params]);

//   return (
//     <div
//       className="flex flex-col justify-center items-center fixed inset-0"
//       style={{ zIndex: "10000" }}
//     >
//       <img src={logo} className="h-[7vh]" alt="Logo" />
//       {!validateAccount ? (
//         <div className="relative my-6 mx-auto lg:w-80vw md:w-70vw sm:w-80vw w-80vw w-full">
//           <div className="px-5 w-full h-[80vh] flex-grow">
//             <WizardUI
//               canSave={canSave}
//               saveSetter={setCanSave}
//               canContinue={canContinue}
//             >
//               <PatientBasicDetails
//                 patientDetails={patientDetails}
//                 detailsSetter={setPatientDetails}
//                 setCanContinue={setCanContinue}
//               />
//               <PatientQuestionaire
//                 questionaire={questionaire}
//                 questionaireSetter={setQuestionaire}
//                 setCanContinue={setCanContinue}
//               />
//               <PatientChecks
//                 odontologicPast={odontologicPast}
//                 pastSetter={setOdontologicPast}
//                 setCanContinue={setCanContinue}
//               />
//             </WizardUI>
//           </div>
//           <div className="flex items-center justify-end p-3 border-t border-blueGray-200 rounded-b">
//             {canSave && (
//               <button
//                 type="button"
//                 onClick={saveClientHandler}
//                 className="flex bg-emerald-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg transition-all duration-150"
//               >
//                 Guardar
//                 {isLoading && (
//                   <Spinner
//                     size="small"
//                     fill="fill-emerald-800"
//                     className="ml-2"
//                   />
//                 )}
//               </button>
//             )}
//           </div>
//         </div>
//       ) : (
//         <div className="relative my-6 mx-auto lg:w-80vw md:w-70vw sm:w-80vw w-80vw w-full bg-white shadow-lg rounded-lg p-6 border border-gray-300">
//           <h2 className="text-2xl font-extrabold text-center text-gray-800 mb-4">
//             ¡Bienvenido a tu espacio de salud!
//           </h2>
//           <p className="text-center text-gray-700 mb-4">
//             <strong>Accedé a tus detalles médicos, citas y servicios.</strong>
//           </p>
//           <div className="flex justify-center">
//             <button className="px-6 py-3 text-white bg-slate-700 hover:bg-slate-800 rounded-md transition duration-200 focus:outline-none shadow-lg">
//               Verificar Cuenta
//             </button>
//           </div>
//           <p className="text-center text-gray-600 mt-4">
//             Por favor verifica tus datos para seguir.
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default PatientSelfRegister;
