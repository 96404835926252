import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { deleteRecordById } from "../../firebase/firebase.patientHistory";
import toast from "react-hot-toast";

const DeleteModal = ({
  modalState,
  modalSetter,
  historyToDelete,
  allRecords,
}) => {
  const [open, setOpen] = useState(true);
  const [type, setType] = useState(null);
  const [title, setTitle] = useState(null);
  const [subtitle, setSubtitle] = useState(null);
  const [date, setDate] = useState(null);
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (historyToDelete) {
      setType(historyToDelete?.selectedHistory?.type);
      setTitle(historyToDelete?.selectedHistory?.title);
      setSubtitle(historyToDelete?.selectedHistory?.subtitle);
      setDate(historyToDelete?.selectedHistory?.date);
    }
  }, [historyToDelete]);

  useEffect(() => {
    if (modalState !== open) {
      setOpen(modalState);
    }
  }, [open]);

  const deleteHistory = () => {
    deleteRecordById(
      historyToDelete.selectedHistory.id,
      allRecords,
      historyToDelete?.historyStateSetter
    );
    modalSetter(false);
  };

  return (
    // <Transition.Root show={open} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     className="relative z-10"
    //     initialFocus={cancelButtonRef}
    //     onClose={setOpen}
    //   >
    //     <Transition.Child
    //       as={Fragment}
    //       enter="ease-out duration-300"
    //       enterFrom="opacity-0"
    //       enterTo="opacity-100"
    //       leave="ease-in duration-200"
    //       leaveFrom="opacity-100"
    //       leaveTo="opacity-0"
    //     >
    //       <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    //     </Transition.Child>

    //     <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
    //       <div
    //         className="flex min-h-full items-end p-4 text-center sm:items-center sm:p-0"
    //         style={{ justifyContent: "center" }}
    //       >
    //         <Transition.Child
    //           as={Fragment}
    //           enter="ease-out duration-300"
    //           enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //           enterTo="opacity-100 translate-y-0 sm:scale-100"
    //           leave="ease-in duration-200"
    //           leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    //           leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    //         >
    //           <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
    //             <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
    //               <div className="sm:flex sm:items-start">
    //                 <div
    //                   className="mx-auto flex h-12 w-12 flex-shrink-0 items-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
    //                   style={{ justifyContent: "center" }}
    //                 >
    //                   <ExclamationTriangleIcon
    //                     className="h-6 w-6 text-red-600"
    //                     aria-hidden="true"
    //                   />
    //                 </div>
    //                 <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
    //                   <Dialog.Title
    //                     as="h3"
    //                     className="text-base font-semibold leading-6 text-gray-900"
    //                   >
    //                     Seguro que quieres eliminar este historial?
    //                   </Dialog.Title>
    //                   <div className="mt-2">
    //                     <div className="flex flex-col">
    //                       <span className="mb-4 text-sm leading-normal text-black">
    //                         {type}
    //                       </span>
    //                       <span className="mb-2 text-xs leading-tight text-black/80">
    //                         Categoria:{" "}
    //                         <span className="font-semibold text-slate-700 text-black sm:ml-2">
    //                           {title}
    //                         </span>
    //                       </span>
    //                       <span className="mb-2 text-xs leading-tight text-black/80">
    //                         Descripción:{" "}
    //                         <span className="font-semibold whitespace-pre-wrap text-slate-700 text-black sm:ml-2">
    //                           {subtitle}
    //                         </span>
    //                       </span>
    //                       <span className="text-xs leading-tight text-black/80">
    //                         Fecha:{" "}
    //                         <span className="font-semibold text-slate-700 text-black sm:ml-2">
    //                           {date?.seconds === undefined
    //                             ? date
    //                             : new Date(
    //                                 Date(date.seconds)
    //                               ).toLocaleDateString()}
    //                         </span>
    //                       </span>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
    //               <button
    //                 type="button"
    //                 className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
    //                 onClick={deleteHistory}
    //               >
    //                 Eliminar
    //               </button>
    //               <button
    //                 type="button"
    //                 className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
    //                 onClick={() => modalSetter(false)}
    //                 ref={cancelButtonRef}
    //               >
    //                 Cancel
    //               </button>
    //             </div>
    //           </Dialog.Panel>
    //         </Transition.Child>
    //       </div>
    //     </div>
    //   </Dialog>
    // </Transition.Root>
    <>
      {modalState &&
        title &&
        toast.custom(
          (t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex p-4">
                <div className="flex items-center">
                  <div className="">
                    <p className="text-sm font-medium text-gray-900">
                      {`Seguro que quieres eliminar este historial? ${title}`}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => {
                      toast.dismiss(t.id);
                      modalSetter(false);
                    }}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-slate-900"
                  >
                    Cancelar
                  </button>
                </div>
                <div className="flex justify-center">
                  <div className="h-[0.2vh] w-3/4 bg-slate-800"></div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={deleteHistory}
                    className="w-full border border-transparent rounded-none rounded-r-lg p-2 flex items-center justify-center text-sm font-medium text-slate-800 hover:text-red-600"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          ),
          {
            duration: 7000,
          }
        )}
    </>
  );
};

export default DeleteModal;
