import React from "react";

const PersonalDetails = ({ detail }) => {
  return (
    <div class="mt-4 flex flex-col space-y-4">
      <div class="w-full flex flex-col">
        <div class="flex-1 bg-white rounded-lg shadow-xl p-8">
          <h4 class="text-xl text-gray-900 font-bold">Informacion Personal</h4>
          <ul class="mt-2 text-gray-700">
            <li class="flex border-b py-2 justify-between">
              <span className="font-bold">CI:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.identityNumber ? detail?.identityNumber : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span className="font-bold">Nombre:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.name ? detail?.name : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span className="font-bold">Nacimiento:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.birthdate ? detail?.birthdate : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span class="font-bold">Nacionalidad:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.nationality?.label ? detail?.nationality?.label : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span className="font-bold">Celular:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.tel ? detail?.tel : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span className="font-bold">Email:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.email ? detail?.email : "-"
              }`}</span>
            </li>
            <li className="flex border-b py-2 justify-between">
              <span className="font-bold">Dirección:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.personalAddress ? detail?.personalAddress : "-"
              }`}</span>
            </li>
            <li class="flex py-2 justify-between">
              <span className="font-bold">RUC:</span>
              <span className="text-gray-700 ml-2">{`${
                detail?.ruc ? detail?.ruc : "-"
              }`}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
