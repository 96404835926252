import React from "react";
import Spinner from "../../../UI/Spinner";
import ImgCard from "../../patientViews/allViews/imgDashboard/ImgCard";

const AllPatientImgs = ({ allImgs, setEditImg, setAllImgs }) => {
  return (
    <div className="h-full bg-white rounded-lg w-full">
      <div className="overflow-scroll">
        <div className="flex w-full h-[50vh] flex-wrap">
          {allImgs ? (
            allImgs.map((e, i) => {
              return (
                <ImgCard
                  key={i}
                  url={e.imageUrl}
                  id={e.id}
                  title={e.title}
                  description={e.description}
                  setEditImg={setEditImg}
                  imgObj={e}
                  setAllImgs={setAllImgs}
                  allImgs={allImgs}
                  readOnly={true}
                />
              );
            })
          ) : (
            <div
              className="flex items-center justify-center"
              style={{ height: "50vh", width: "100vw" }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPatientImgs;
